import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {GlobalComponent} from '../global.component';

@Injectable()

export class PlanSustantivoService{
    public url: string;
    
    constructor(
        public _http: HttpClient
        ){
        this.url=GlobalComponent.url;
        
    }
    
    getPlanSustantivoData(token: string, gestion): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.get(this.url + 'plan_sustantivo/data' + gestion,{headers:headers});
    }
}