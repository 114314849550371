<div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block">
                                <div style="height: 100%;">
                                    <img class="mb-4" src="../../assets/img/05_mag.png" alt="" style="width: 100%;" />
                                </div>
                                
                            </div>
                            <div class="col-lg-6 align-middle">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4">Bienvenido!</h1>
                                    </div>
                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-user"
                                                id="username" name="username" [(ngModel)]="username"
                                                placeholder="Nombre de Usuario">
                                        </div>
                                        <div class="form-group">
                                            <input type="password" class="form-control form-control-user"
                                                   id="password" name="password" placeholder="Contrase&ntilde;a" [(ngModel)]="password">
                                        </div>
                                        <div class="form-group">
                                            <p-dropdown [options]="gestiones" [(ngModel)]="gestion"  inputId="gestion"  placeholder="[Seleccione]" optionLabel="gestion" optionValue="valor" appendTo="body" [style]="{width: '100%'}"></p-dropdown>
                                        </div>
                                        <!--
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox small">
                                                <input type="checkbox" class="custom-control-input" id="customCheck">
                                                <label class="custom-control-label" for="customCheck">Remember
                                                    Me</label>
                                            </div>
                                        </div>
                                        -->
                                        <a href="#" class="btn btn-primary btn-user btn-block" (click)="login()">
                                            Acceder
                                        </a>
                                    
                                    <!--
                                    <hr>
                                    <div class="text-center">
                                        <a class="small" href="forgot-password.html">Forgot Password?</a>
                                    </div>
                                    <div class="text-center">
                                        <a class="small" href="register.html">Create an Account!</a>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

