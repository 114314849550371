import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {UsuariosService} from '../services/usuarios.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers:[UsuariosService]
})
export class LoginComponent implements OnInit {
    public username:string='';
    public password:string='';
    public mensaje:string;
    public gestiones:Array<any>;
    public gestion: string='';

    constructor(
        private _usuariosService: UsuariosService,
        private _toastr: ToastrService,
        private _route: ActivatedRoute,
        private _router: Router
        ){
        this.mensaje='';
        
        
    }

    ngOnInit(): void {
        this.gestiones=[];
        const currentYear  = new Date().getFullYear();
        for(let g=2009;g<=currentYear;g++){
            let valor: string=String(g);
            if(g==currentYear){
                valor='';
            }
            this.gestiones.push({
                'valor': valor,
                'gestion': g
            });
        }
    }
    
    login(){
        this.mensaje='';
        this._usuariosService.login(this.username, this.password).subscribe(
            response =>{
                this._usuariosService.setToken(response.jwt);
                this._usuariosService.setGestion(this.gestion);
                this._router.navigate(['/inicio']);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
    }

}
