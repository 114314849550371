import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UsuariosService} from '../services/usuarios.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

import {UsuariosModel} from '../models/usuarios.model';

@Component({
    selector: 'app-menu-superior',
    templateUrl: './menu-superior.component.html',
    styleUrls: ['./menu-superior.component.css'],
    providers:[UsuariosService,ToastrService]
})
export class MenuSuperiorComponent implements OnInit {
    public tokenDetalle: any;
    public token:string;
    public _idtipousuario: number;
    public usuario: UsuariosModel;
    public gestiones:Array<any>;
    public gestion: string;
    
    displayModal: boolean;

    constructor(
        private _usuariosService: UsuariosService,
        private _toastr: ToastrService,
        private _router: Router
    ){
        this.token = this._usuariosService.getToken();
        this.gestion = this._usuariosService.getGestion();
        this.usuario = {} as any;
    }

    ngOnInit(): void {
        if(this.token=='' || this.token==null){
            this._router.navigate(['/login']);
        }
        this.tokenDetalle = this._usuariosService.getTokenDetalle();
        this.gestiones=[];
        const currentYear  = new Date().getFullYear();
        for(let g=2009;g<=currentYear;g++){
            let valor: string=String(g);
            if(g==currentYear){
                valor='';
            }
            this.gestiones.push({
                'valor': valor,
                'gestion': g
            });
        }
        this._usuariosService.getUsuarioData(this.token, this.tokenDetalle.idusuario).subscribe(
            response =>{
                this.usuario=response;
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
    }
    
    
    logOut(){
        this._usuariosService.logout();
        this._router.navigate(['/login']);
    }
    sidebarToggle(){
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
            $('.sidebar .collapse').collapse('hide');
        };
    }
    
    showModalDialog() {
        this.displayModal = true;
    }
    
    cambiarGestion(){
        this._usuariosService.setGestion(this.gestion);
        this.displayModal = false;
        window.location.reload();
    }

}
