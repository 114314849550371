<!--<menu style="margin-top: 0px; padding-left: 0px"></menu>-->
<!-- Page Wrapper -->
<div id="wrapper">
    <!-- Sidebar -->
    <app-menu-lateral style="background-color: #224abe;"></app-menu-lateral>
    <!-- End of Sidebar -->
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
            <!-- Topbar -->
            <app-menu-superior></app-menu-superior>
            <!-- End of Topbar -->
            <!-- Begin Page Content -->
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Plan de Producci&oacute;n</li>
                    </ol>
                </nav>
                <br />
                <p-table [value]="plan_produccion" #dt [rows]="10" dataKey="id" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-sm" responsiveLayout="stack"
                    [paginator]="true" currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} registros"
                    [globalFilterFields]="['cic_nro','nombre_apellido','sexo','tipo','planproduccion','edad','departamento','distrito']">
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex">
                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-ml-auto"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 100px">
                                <div class="flex justify-content-center align-items-center">
                                    CI
                                    <p-columnFilter type="text" field="cic_nro" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-center align-items-center">
                                    Nombre y Apellido
                                    <p-columnFilter type="text" field="nombre_apellido" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th style="width: 100px">
                                <div class="flex justify-content-center align-items-center">
                                    Sexo
                                    <p-columnFilter type="text" field="sexo" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-center align-items-center">
                                    Tipo de Beneficiarios
                                    <p-columnFilter type="text" field="tipo" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-center align-items-center">
                                    Plan de Produccion
                                    <p-columnFilter type="text" field="planproduccion" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-center align-items-center">
                                    Departamento
                                    <p-columnFilter type="text" field="departamento" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-center align-items-center">
                                    Distrito
                                    <p-columnFilter type="text" field="distrito" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th style="width: 100px">
                                <div class="flex justify-content-center align-items-center">
                                    Edad
                                    <p-columnFilter type="numeric" field="edad" display="menu"></p-columnFilter>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-planproduccion>
                        <tr>
                            <td>{{planproduccion.cic_nro}}</td>
                            <td>{{planproduccion.nombre_apellido}}</td>
                            <td>{{planproduccion.sexo}}</td>
                            <td>{{planproduccion.tipo}}</td>
                            <td>{{planproduccion.planproduccion}}</td>
                            <td>{{planproduccion.departamento}}</td>
                            <td>{{planproduccion.distrito}}</td>
                            <td>{{planproduccion.edad}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>