import { Component, OnInit } from '@angular/core';
import {UsuariosService} from '../services/usuarios.service';
import {PlanService} from '../services/plan.service';
import {PlanIndigenaService} from '../services/plan-indigena.service';
import {PlanSustantivoService} from '../services/plan-sustantivo.service';
import {PlanProduccionModel} from "../models/plan-produccion.model";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-plan-produccion',
    templateUrl: './plan-produccion.component.html',
    styleUrls: ['./plan-produccion.component.css'],
    providers:[UsuariosService,PlanService,PlanIndigenaService,PlanSustantivoService]
})
export class PlanProduccionComponent implements OnInit {
    public token: string;
    public gestion: string;
    
    public plan_produccion: Array<PlanProduccionModel>;
    public plan_produccion_xlsx: Array<any>;

    constructor(
        private _usuariosService: UsuariosService,
        private _planindigenaService: PlanIndigenaService,
        private _planService: PlanService,
        private _plansustantivoService: PlanSustantivoService,
        private _toastr: ToastrService
        ) {
            this.token = this._usuariosService.getToken();
            this.gestion = this._usuariosService.getGestion();
    }

    ngOnInit(): void {
        this.plan_produccion=[];
        this.plan_produccion_xlsx=[];
        var plan_produccion_aux=[];
        this._planindigenaService.getPlanIndigenaData(this.token, this.gestion).subscribe(
            response =>{
                //console.log("PlanIndigena:");
                //console.log(response);
                //this.planindigena=response;
                response.forEach(function(planindigena) {
                    plan_produccion_aux.push({
                        cic_nro: planindigena.cic_nro,
                        nombre_apellido: planindigena.nombre_apellido,
                        fecha_nac: planindigena.fecha_nac,
                        edad: planindigena.edad,
                        sexo: planindigena.sexo,
                        tipo: planindigena.tipo,
                        planproduccion: planindigena.planproduccion,
                        cod_dpto: planindigena.cod_dpto,
                        departamento: planindigena.departamento,
                        id_distrito: planindigena.id_distrito,
                        distrito: planindigena.distrito
                    });
                });
                this._planService.getPlanData(this.token, this.gestion).subscribe(
                    response_plan =>{
                        console.log("Plan:");
                        console.log(response_plan);
                        response_plan.forEach(function(plan) {
                            plan_produccion_aux.push({
                                cic_nro: plan.cic_nro,
                                nombre_apellido: plan.nombre_apellido,
                                fecha_nac: plan.fecha_nac,
                                edad: plan.edad,
                                sexo: plan.sexo,
                                tipo: plan.tipo,
                                planproduccion: plan.planproduccion,
                                cod_dpto: plan.cod_dpto,
                                departamento: plan.departamento,
                                id_distrito: plan.id_distrito,
                                distrito: plan.distrito
                            });
                        });
                        this._plansustantivoService.getPlanSustantivoData(this.token, this.gestion).subscribe(
                            response_plansustantivo =>{
                                console.log("Plan Sustantivo:");
                                console.log(response_plansustantivo);
                                response_plansustantivo.forEach(function(plansustantivo) {
                                    plan_produccion_aux.push({
                                        cic_nro: plansustantivo.cic_nro,
                                        nombre_apellido: plansustantivo.nombre_apellido,
                                        fecha_nac: plansustantivo.fecha_nac,
                                        edad: plansustantivo.edad,
                                        sexo: plansustantivo.sexo,
                                        tipo: plansustantivo.tipo,
                                        planproduccion: plansustantivo.planproduccion,
                                        cod_dpto: plansustantivo.cod_dpto,
                                        departamento: plansustantivo.departamento,
                                        id_distrito: plansustantivo.id_distrito,
                                        distrito: plansustantivo.distrito
                                    });
                                });
                                this.plan_produccion = plan_produccion_aux;
                                for (let at = 0; at<this.plan_produccion.length; at++){
                                    this.plan_produccion_xlsx.push({
                                        'CI': this.plan_produccion[at].cic_nro,
                                        'Nombre y Apellido': this.plan_produccion[at].nombre_apellido,
                                        'Sexo': this.plan_produccion[at].sexo,
                                        'Tipo de Beneficiarios': this.plan_produccion[at].tipo,
                                        'Plan de Produccion': this.plan_produccion[at].planproduccion,
                                        'Departamento': this.plan_produccion[at].departamento,
                                        'Distrito': this.plan_produccion[at].distrito,
                                        'Edad': this.plan_produccion[at].edad
                                    });
                                }
                                console.log(this.plan_produccion);
                            },
                            error=>{
                                let mensaje_error=<any>error;
                                this._toastr.error(mensaje_error.error.mensaje, 'Error');
                            }
                        );
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._toastr.error(mensaje_error.error.mensaje, 'Error');
                    }
                );
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
                

        
        
                        
        
    }
    
    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.plan_produccion_xlsx);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "PlanProduccion");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import("file-saver").then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

}
