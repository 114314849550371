import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {GlobalComponent} from '../global.component';

@Injectable()

export class PpiPromafiService{
    public url: string;
    
    constructor(
        public _http: HttpClient
        ){
        this.url=GlobalComponent.url;
        
    }
    
    getPpiPromafiData(token: string): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.get(this.url + 'ppipromafi',{headers:headers});
    }
    
    savePpiPromafiData(token: string, ppipromafiid: number, params: {}): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.put(this.url + 'ppipromafi/'+ppipromafiid,JSON.stringify(params),{headers:headers});
    }
    
    addPpiPromafiData(token: string, params: {}): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.post(this.url + 'ppipromafi',JSON.stringify(params),{headers:headers});
    }
    
    deletePpiPromafiData(token: string,ppipromafiid: number): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.delete(this.url + 'ppipromafi/' + ppipromafiid,{headers:headers});
    }
    
    agregarGestionData(token: string,gestion: string): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.post(this.url + 'ppipromafi/agregargestion/' + gestion,null,{headers:headers});
    }
}