import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {GlobalComponent} from '../global.component';
import { CookieService } from "ngx-cookie-service";
import jwt_decode from "jwt-decode";

@Injectable()

export class UsuariosService{
    public url: string;
    
    constructor(
        public _http: HttpClient,
        private _cookies: CookieService
        ){
        this.url=GlobalComponent.url;
        
    }
    
    login(username: string, password: string): Observable<any>{
        let params = 'username='+username+'&password='+password;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'usuarios/login',params,{headers:headers});
    }
    
    logout(){
        this._cookies.delete("token");
    }
    setToken(token: string) {
        this._cookies.set("token", token);
    }
    getToken() {
        return this._cookies.get("token");
    }
    getTokenDetalle(){
        if(this.getToken()!=='' && this.getToken()!==null){
            return jwt_decode(this.getToken());
        }else{
            return [];
        }
    }
    
    getUsuarioData(token: string, idusuarios: number): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.get(this.url + 'usuarios/' + idusuarios,{headers:headers});
    }
    
    setGestion(gestion: string) {
        this._cookies.set("gestion", gestion);
    }
    
    getGestion() {
        return this._cookies.get("gestion");
    }
    
    
    /*
    getUsuariosData(token: string): Observable<any>{
        let params = 'token=' + token;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'getUsuariosData',params,{headers:headers});
    }
    
    getTipoUsuariosData(token: string): Observable<any>{
        let params = 'token=' + token;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'getTipoUsuariosData',params,{headers:headers});
    }
    
    addUsuariosData(token: string, nombre: string, email: string, idtipousuario: number, username: string, contrasena: string): Observable<any>{
        let params = 'token=' + token+'&nombre='+nombre+'&email='+email+'&idtipousuario='+idtipousuario+'&username='+username+'&contrasena='+contrasena;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'addUsuariosData',params,{headers:headers});
    }
    
    activarUsuarioData(token: string, idusuario: number, activo: boolean): Observable<any>{
        let params = 'token=' + token+'&idusuario='+idusuario+'&activo='+activo;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'activarUsuarioData',params,{headers:headers});
    }
    
    saveUsuarioData(token: string, idusuario: number, idtipousuario: number, permisos: Array<any>): Observable<any>{
        let jsonpermisos=JSON.stringify(permisos);
        let params = 'token=' + token+'&idusuario='+idusuario+'&idtipousuario='+idtipousuario+'&permisos='+jsonpermisos;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'saveUsuarioData',params,{headers:headers});
    }
    
    resetPassword(token: string, idusuario: number): Observable<any>{
        let params = 'token=' + token+'&idusuario='+idusuario;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'resetPassword',params,{headers:headers});
    }
    
    savePerfilData(token: string, idusuario: number, nombre: string, email: string, username: string): Observable<any>{
        let params = 'token=' + token+'&idusuario='+idusuario+'&nombre='+nombre+'&email='+email+'&username='+username;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'savePerfilData',params,{headers:headers});
    }
    
    verificarPass(token: string, idusuario: number, contrasena: string): Observable<any>{
        let params = 'token=' + token+'&idusuario='+idusuario+'&contrasena='+contrasena;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'verificarPass',params,{headers:headers});
    }
    
    saveContrasenaData(token: string, idusuario: number, contrasena: string): Observable<any>{
        let params = 'token=' + token+'&idusuario='+idusuario+'&contrasena='+contrasena;
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this._http.post(this.url+'saveContrasenaData',params,{headers:headers});
    }
    */
    
    
    
    
    
    
    
}