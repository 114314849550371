<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
        <div class="sidebar-brand-icon rotate-n-15">
            <i class="bi bi-droplet-half"></i>
        </div>
        <div class="sidebar-brand-text mx-3">MAG</div>
    </a>
            <li class="nav-item active">
                <a class="nav-link">
                    <i class="bi bi-calendar"></i>
                    <span> Gesti&oacute;n {{gestion_mostrar}}</span></a>
            </li>
            <!-- Divider -->
            <hr class="sidebar-divider my-0">

            <!-- Nav Item - Dashboard -->
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/inicio']">
                    <i class="bi bi-house"></i>
                    <span> INICIO</span></a>
            </li>

            <!-- Divider -->
            <hr class="sidebar-divider">

            <!-- Nav Item - Pages Collapse Menu -->
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/asistencia-tecnica']">
                    <i class="bi bi-journal"></i>
                    <span> ASIST. TECNICA</span></a>
            </li>
            
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/inversiones']">
                    <i class="bi bi-cash-stack"></i>
                    <span> INVERSIONES</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/plan-produccion']">
                    <i class="bi bi-cash-stack"></i>
                    <span> PLAN PRODUCCION</span></a>
            </li>
            <!--
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/contratos']">
                    <fa-icon [icon]="faFileSignature"></fa-icon>
                    <span> CONTRATOS/SOLICITUDES</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/pagos']">
                    <fa-icon [icon]="faMoneyCheckAlt"></fa-icon>
                    <span> PAGOS</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/desembolsos']">
                    <fa-icon [icon]="faCommentDollar"></fa-icon>
                    <span> DESEMBOLSOS</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/lineabase']">
                    <fa-icon [icon]="faDatabase"></fa-icon>
                    <span> LINEA BASE</span></a>
            </li>
            ->
            
            <!-- Divider -->
            <hr class="sidebar-divider">

            <!-- Nav Item - Datos Collapse Menu -->
            <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseDatos" aria-expanded="true" aria-controls="collapseDatos" id="navbarDropdownMenuLink" >
                    <i class="bi bi-gear"></i>
                    <span> DATOS</span>
                </a>
                <div id="collapseDatos" class="collapse" aria-labelledby="headingDatos"
                    data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <!--<a class="collapse-item" [routerLink]="['/proyecto']">Proyecto</a>-->
                        <a class="collapse-item" [routerLink]="['/vmg']">VMG</a>
                        <a class="collapse-item" [routerLink]="['/ppi-promafi']">PPI/PROMAFI</a>
                    </div>
                </div>
            </li>
            
            <!-- Divider -->
            <hr class="sidebar-divider">

            <!-- Nav Item - Paramteros Collapse Menu -->
            <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseParamteros" aria-expanded="true" aria-controls="collapseParamteros" id="navbarDropdownMenuLink" >
                    <i class="bi bi-gear"></i>
                    <span> PARAMETROS</span>
                </a>
                <div id="collapseParamteros" class="collapse" aria-labelledby="headingParamteros"
                    data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Parametrizaci&oacute;n:</h6>
                        <!--<a class="collapse-item" [routerLink]="['/proyecto']">Proyecto</a>-->
                        <a class="collapse-item">Usuarios</a>
                        <a class="collapse-item">Componentes</a>
                    </div>
                </div>
            </li>

            <!-- Divider -->
            <hr class="sidebar-divider">

            <!-- Heading -->
            <!--
            <div class="sidebar-heading">
                Addons
            </div>
            -->

            <!-- Nav Item - Pages Collapse Menu -->
            <!--
            <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
                    aria-expanded="true" aria-controls="collapsePages">
                    <fa-icon [icon]="faFileInvoice"></fa-icon>
                    <span> REPORTES</span>
                </a>
                <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Reportes:</h6>
                        <a *ngFor="let reporte of reportes" class="collapse-item" routerLink="/{{reporte.enlace}}">{{reporte.reporte}}</a>
                        <div class="collapse-divider"></div>
                        <h6 class="collapse-header">Manuales:</h6>
                        <a class="collapse-item" target="_blank" href="../../assets/manuales/Manual_PISCEA.pdf">Manual de Usuario</a>
                    </div>
                </div>
            </li>
            -->
            <!-- Divider -->
            <hr class="sidebar-divider d-none d-md-block">

            <!-- Sidebar Toggler (Sidebar) -->
            <div class="text-center d-none d-md-inline">
                <button class="rounded-circle border-0" id="sidebarToggle" (click)="sidebarToggle()"></button>
            </div>
        </ul>