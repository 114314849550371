<!--<menu style="margin-top: 0px; padding-left: 0px"></menu>-->
<!-- Page Wrapper -->
<div id="wrapper">
    <!-- Sidebar -->
    <app-menu-lateral style="background-color: #224abe;"></app-menu-lateral>
    <!-- End of Sidebar -->
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
            <!-- Topbar -->
            <app-menu-superior></app-menu-superior>
            <!-- End of Topbar -->
            <!-- Begin Page Content -->
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
                        <li class="breadcrumb-item active" aria-current="page">VMG</li>
                    </ol>
                </nav>
                <br />
                <p-toast></p-toast>
                <p-contextMenu #cm [model]="items"></p-contextMenu>
                <p-confirmDialog icon="pi pi-exclamation-triangle" acceptLabel="Si" rejectLabel="No"></p-confirmDialog>
                <p-table [value]="vmg" #dt [rows]="10" dataKey="id" [(contextMenuSelection)]="vmgSeleccionado" [contextMenu]="cm" [scrollable]="true" [style]="{width:'100%'}" [showCurrentPageReport]="true" [resizableColumns]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-sm" responsiveLayout="stack"  editMode="row" dataKey="vmgid"
                    [paginator]="true" currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} registros"
                    [globalFilterFields]="['nombre','fechanac','edad','sexo','ci','tipoproyecto','dptonombre','dtonombre','organizacion','organizacionruc']">
                    
                    
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex">
                            <button type="button" pButton pRipple icon="pi pi-plus" class="p-mr-2 p-button-rounded p-button-outlined" (click)="nuevoVmg()"></button>
                            <button type="button" pButton pRipple icon="pi pi-calendar" class="p-button-rounded p-button-secondary p-button-outlined p-mr-2" (click)="displayModalGestion=true"></button>
                            <button type="button" pButton pRipple icon="pi pi-calendar-plus" class="p-button-rounded p-button-help p-button-outlined p-mr-2" (click)="displayModalRubros=true"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-ml-auto"  pTooltip="XLS" tooltipPosition="bottom"></button>
                            <!--<button type="button" pButton pRipple icon="pi pi-filter" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button>-->
                        </div>
                        <!--<button type="button" pButton pRipple class="p-mr-2" label="Nuevo"></button>-->
                            
                        
                        <!--
                        <div class="p-d-flex">
                            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button>
                        </div>
                        -->
                    </ng-template>
                    
                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <col style="width: 6rem;">
                            <col style="width: 300px">
                            <col style="width: 100px">
                            <col style="width: 140px">
                            <col style="width: 100px">
                            <col style="width: 130px">
                            <col style="width: 130px">
                            <col style="width: 200px">
                            <col style="width: 200px">
                            <col style="width: 300px">
                            <col style="width: 150px">
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th></th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Nombre y Apellido
                                    <p-columnFilter type="text" field="nombre" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Sexo
                                    <p-columnFilter type="text" field="sexo" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Fecha Nac.
                                    <p-columnFilter type="date" field="fechanac" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Edad
                                    <p-columnFilter type="numeric" field="edad" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    CI
                                    <p-columnFilter type="text" field="ci" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Proyecto
                                    <p-columnFilter type="text" field="tipoproyecto" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Departamento
                                    <p-columnFilter type="text" field="dtonombre" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Distrito
                                    <p-columnFilter type="text" field="dtonombre" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Organizaci&oacute;n
                                    <p-columnFilter type="text" field="organizacion" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    RUC
                                    <p-columnFilter type="text" field="organizacionruc" display="menu"></p-columnFilter>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-vmgs let-editing="editing" let-ri="rowIndex">
                        <tr [pContextMenuRow]="vmgs" [pEditableRow]="vmgs">
                            
                            <td>
                                <!--<i class="bi bi-folder2-open" role="button" data-toggle="modal" data-target="#exampleModal"></i>-->
                                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(vmgs)" class="p-button-rounded p-button-text"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(vmgs)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(vmgs, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                            </td>
                            
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" name="nombre{{vmgs.vmgid}}" [(ngModel)]="vmgs.nombre" style="width: 100%;" (change)="error_nombre=false">
                                        <small class="text-danger" *ngIf="error_nombre"><br />Campo Requerido</small>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{vmgs.nombre}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-radioButton name="groupname{{vmgs.vmgid}}" value="M" [(ngModel)]="vmgs.sexo" inputId="sexom{{vmgs.vmgid}}"></p-radioButton>
                                        <label for="sexom{{vmgs.vmgid}}">M</label>
                                        <p-radioButton name="groupname{{vmgs.vmgid}}" value="F" [(ngModel)]="vmgs.sexo" inputId="sexof{{vmgs.vmgid}}"></p-radioButton>
                                        <label for="sexof{{vmgs.vmgid}}">F</label>

                                            
                                        <!--
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="sexom{{vmgs.vmgid}}" name="sexo{{vmgs.vmgid}}" value="M" [(ngModel)]="vmgs.sexo" class="custom-control-input">
                                            <label class="custom-control-label" for="sexom{{vmgs.vmgid}}">M</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="sexof{{vmgs.vmgid}}" name="sexo{{vmgs.vmgid}}" value="F" [(ngModel)]="vmgs.sexo" class="custom-control-input">
                                            <label class="custom-control-label" for="sexof">F</label>
                                        </div>
                                        -->
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{vmgs.sexo}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-calendar dateFormat="dd/mm/yy" inputId="fechanac{{vmgs.vmgid}}" appendTo="body" [(ngModel)]="vmgs.fechanac" (ngModelChange)="calcularEdadEdit(vmgs)" ></p-calendar>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{vmgs.fechanac | date:'dd/MM/yyyy'}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>{{vmgs.edad}}</td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" step="1" [(ngModel)]="vmgs.ci" style="width: 100%;" >
                                        <!--<p-inputNumber [(ngModel)]="vmgs.ci" mode="decimal" name="ci" inputId="ci" [useGrouping]="false" style="width: 100%;"></p-inputNumber>-->
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{vmgs.ci}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [options]="tipoproyectos" [(ngModel)]="vmgs.idtipoproyecto"  placeholder="[Seleccione]" optionLabel="tipoproyecto" optionValue="idtipoproyecto" appendTo="body" [style]="{width: '100%'}" (onChange)="cambioTipoProyectoEdit(vmgs)"  ></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{vmgs.tipoproyecto}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [options]="departamentos" [(ngModel)]="vmgs.dptoid"  placeholder="[Seleccione]" optionLabel="dptonombre" optionValue="dptoid" [filter]="true" [showClear]="true" appendTo="body" [style]="{width: '100%'}" (onChange)="cambioDepartamentoEdit(vmgs)" ></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{vmgs.dptonombre}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [options]="distritosFiltradoEdit[vmgs.vmgid]" [(ngModel)]="vmgs.dptodtoid"  placeholder="[Seleccione]" optionLabel="dtonombre" optionValue="dtoid" [filter]="true" [showClear]="true" appendTo="body" [style]="{width: '100%'}" [disabled]="vmgs.dptoid==null" (onChange)="cambioDistritoEdit(vmgs)" ></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{vmgs.dtonombre}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="vmgs.organizacion" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{vmgs.organizacion}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="vmgs.organizacionruc" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{vmgs.organizacionruc}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<p-dialog header="{{cabeceraModal}}" [(visible)]="displayModal" [modal]="true" [style]="{width: '70vw'}" 
    [draggable]="true" [resizable]="false" (onHide)="cerrarVentana()">
        <!--
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="sexom" name="sexo" value="M" [(ngModel)]="vmgSeleccionado.sexo" class="custom-control-input">
                    <label class="custom-control-label" for="sexom">Masc.</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="sexof" name="sexo" value="F" [(ngModel)]="vmgSeleccionado.sexo" class="custom-control-input">
                    <label class="custom-control-label" for="sexof">Fem.</label>
                </div>
            </div>
        </div>
        -->
        <p-tabView [(activeIndex)]="activeIndex">
            <p-tabPanel header="Datos Generales">
                <div class="row mb-3">
                    <div class="col-md-3">
                        <label for="ci">CI</label>
                        <div class="input-group mb-3">
                            <input type="number" step="1" [ngClass]="error_ci ? 'form-control is-invalid' : 'form-control'" id="ci" name="ci" [(ngModel)]="vmgSeleccionado.ci" (change)="error_ci=false"  aria-describedby="buscar_ci" />
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" id="buscar_ci" (click)="buscarCI()"><i class="fas fa-search"></i></button>
                            </div>
                            <div class="invalid-feedback">
                                {{mensaje_error_ci}}
                            </div>
                        </div>

                        <!--<input type="number" step="1" [ngClass]="error_ci ? 'form-control is-invalid' : 'form-control'" id="ci" name="ci" [(ngModel)]="vmgSeleccionado.ci" (change)="error_ci=false" />-->

                    </div>
                    <div class="col-md-6">
                        <label for="nombre">Nombre y Apellidos</label>
                        <input type="text" [ngClass]="error_nombre ? 'form-control is-invalid' : 'form-control'" id="nombre" name="nombre" [(ngModel)]="vmgSeleccionado.nombre" (change)="error_nombre=false" />
                        <div class="invalid-feedback">
                            Campo Requerido
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="fechanac">Fecha Nacimiento</label>
                        <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" inputId="fechanac" appendTo="body" [(ngModel)]="vmgSeleccionado.fechanac" (ngModelChange)="calcularEdad()" ></p-calendar>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <label for="dptoid">Departamento</label>
                        <p-dropdown [options]="departamentos" [(ngModel)]="vmgSeleccionado.dptoid"  inputId="dptoid"  placeholder="[Seleccione]" optionLabel="dptonombre" optionValue="dptoid" [filter]="true" [showClear]="true" appendTo="body" [style]="{width: '100%'}" (onChange)="cambioDepartamento()"></p-dropdown>
                    </div>
                    <div class="col-md-6">
                        <label for="dptodtoid">Distrito</label>
                        <p-dropdown [options]="distritosFiltrado" [(ngModel)]="vmgSeleccionado.dptodtoid"  inputId="dptodtoid"  placeholder="[Seleccione]" optionLabel="dtonombre" optionValue="dtoid" [filter]="true" [showClear]="true" appendTo="body" [style]="{width: '100%'}" [disabled]="vmgSeleccionado.dptoid==null" (onChange)="cambioDistrito()" ></p-dropdown>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-3">
                        <label for="dptoid">Proyecto</label>
                        <p-dropdown [options]="tipoproyectos" [(ngModel)]="vmgSeleccionado.idtipoproyecto" [ngClass]="error_tipoproyecto ? 'ng-invalid ng-dirty' : ''" inputId="idtipoproyecto"  placeholder="[Seleccione]" optionLabel="tipoproyecto" optionValue="idtipoproyecto" appendTo="body" [style]="{width: '100%'}" (onChange)="cambioTipoProyecto();"></p-dropdown>
                        <small class="text-danger" *ngIf="error_tipoproyecto">Campo Obligatorio</small>
                    </div>
                    <div class="col-md-6">
                        <label for="organizacion">Organizaci&oacute;n</label>
                        <input type="text" class="form-control" id="organizacion" name="organizacion" [(ngModel)]="vmgSeleccionado.organizacion" />
                    </div>
                    <div class="col-md-3">
                        <label for="organizacionruc">RUC</label>
                        <input type="text" class="form-control" id="organizacionruc" name="organizacionruc" [(ngModel)]="vmgSeleccionado.organizacionruc" />
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-3">
                        <p>Sexo</p>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sexom" name="sexo" value="M" [(ngModel)]="vmgSeleccionado.sexo" class="custom-control-input">
                            <label class="custom-control-label" for="sexom">Masc.</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sexof" name="sexo" value="F" [(ngModel)]="vmgSeleccionado.sexo" class="custom-control-input">
                            <label class="custom-control-label" for="sexof">Fem.</label>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Agricola">
                <p-messages severity="info">
                    <ng-template pTemplate>
                        S&oacute;lo se muestran los rubros registrados en {{gestion_actual}}.
                    </ng-template>
                </p-messages>
                
                <table class="table table-sm">
                    <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Rubro</th>
                          <th scope="col">Superficie total</th>
                          <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let agricola of vmgSeleccionado.agricola; let ag = index;">
                          <th scope="row">{{ag+1}}</th>
                          <td>
                              <p-dropdown [options]="cultivos" [(ngModel)]="agricola.id_cultivo" inputId="id_cultivo{{ag}}"  placeholder="[Seleccione]" optionLabel="cultivo" optionValue="id_cultivo" appendTo="body" [filter]="true" [style]="{width: '100%'}"></p-dropdown>
                          </td>
                          <td>
                              <input type="number" step="0.01" class="form-control" id="total_sup{{ag}}" [(ngModel)]="agricola.total_sup" name="total_sup{{ag}}" />
                          </td>
                          <td>
                              <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)=eliminarAgricola(ag)></button>
                          </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4">
                                <button type="button" pButton pRipple icon="pi pi-plus" class="p-mr-2 p-button-rounded p-button-outlined" (click)="nuevoAgricola()"></button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </p-tabPanel>
            <p-tabPanel header="Pecuario">
                <p-messages severity="info">
                    <ng-template pTemplate>
                        S&oacute;lo se muestran los rubros registrados en {{gestion_actual}}.
                    </ng-template>
                </p-messages>
                <table class="table table-sm">
                    <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Rubro</th>
                          <th scope="col">Existencia total</th>
                          <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pecuario of vmgSeleccionado.pecuario; let pe = index;">
                          <th scope="row">{{pe+1}}</th>
                          <td>
                              <p-dropdown [options]="pecuarios" [(ngModel)]="pecuario.id_rubro" inputId="id_rubro{{pe}}"  placeholder="[Seleccione]" optionLabel="rubro" optionValue="id_rubro" appendTo="body" [filter]="true" [style]="{width: '100%'}"></p-dropdown>
                          </td>
                          <td>
                              <input type="number" step="1" class="form-control" id="existencia{{pe}}" [(ngModel)]="pecuario.existencia" name="existencia{{pe}}" />
                          </td>
                          <td>
                              <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)=eliminarPecuario(pe)></button>
                          </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="4">
                                <button type="button" pButton pRipple icon="pi pi-plus" class="p-mr-2 p-button-rounded p-button-outlined" (click)="nuevoPecuario()"></button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </p-tabPanel>
            <p-tabPanel header="Gestiones">
                <ng-container *ngFor="let gestions of gestiones; let ag = index;">
                    <div class="p-field-checkbox">
                        <p-checkbox name="gestions" value="{{gestions.gestion}}" [(ngModel)]="vmgSeleccionado.gestion" inputId="gestions{{ag}}"></p-checkbox>
                        <label for="gestions{{ag}}">{{gestions.gestion}}</label>
                    </div>
                </ng-container>
            </p-tabPanel>
        </p-tabView>
        
        
        
                
    
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="guardarDatos()" label="Guardar" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<p-dialog header="Gestion" [(visible)]="displayModalGestion" [modal]="true" [style]="{width: '30vw'}" 
    [draggable]="false" [resizable]="false">
    <p>Agregar la gesti&oacute;n a todos los registros</p>
    <p-dropdown [options]="gestiones" [(ngModel)]="gestion"  inputId="gestion"  placeholder="[Seleccione]" optionLabel="gestion" optionValue="valor" appendTo="body" [style]="{width: '100%'}"></p-dropdown>
    <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="agreagrGestion()" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<p-dialog header="Rubros" [(visible)]="displayModalRubros" [modal]="true" [style]="{width: '30vw'}" 
    [draggable]="false" [resizable]="false">
    <p>Copiar los rubros de la gesti&oacute;n {{gestion_actual}} a</p>
    <p-dropdown [options]="gestiones" [(ngModel)]="gestion" inputId="gestion"  placeholder="[Seleccione]" optionLabel="gestion" optionValue="valor" appendTo="body" [style]="{width: '100%'}"></p-dropdown>
    <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="agreagrRubros()" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>


