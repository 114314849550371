import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {GlobalComponent} from '../global.component';

@Injectable()

export class VmgService{
    public url: string;
    
    constructor(
        public _http: HttpClient
        ){
        this.url=GlobalComponent.url;
        
    }
    
    getVmgData(token: string, gestion: string): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.get(this.url + 'vmg/'+gestion,{headers:headers});
    }
    
    getVmgRubroData(token: string, gestion: string): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.get(this.url + 'vmg/rubro/'+gestion,{headers:headers});
    }
    
    saveVmgData(token: string, vmgid: number, gestion: string, params: {}): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.put(this.url + 'vmg/'+vmgid+'/'+gestion,JSON.stringify(params),{headers:headers});
    }
    
    addVmgData(token: string, gestion: string, params: {}): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.post(this.url + 'vmg/'+gestion,JSON.stringify(params),{headers:headers});
    }
    
    deleteVmgData(token: string,vmgid: number): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.delete(this.url + 'vmg/' + vmgid,{headers:headers});
    }
    
    agregarGestionData(token: string,gestion: string): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.post(this.url + 'vmg/agregargestion/' + gestion,null,{headers:headers});
    }
    
    copiarRubrosData(token: string,gestionorigen: string,gestiondestino: string): Observable<any>{
        let headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded','Authorization':token});
        return this._http.post(this.url + 'vmg/copiarubros/' + gestionorigen + '/' + gestiondestino,null,{headers:headers});
    }
}