import { Component, OnInit } from '@angular/core';
import {UsuariosService} from '../services/usuarios.service';
import {PpiPromafiService} from '../services/ppi-promafi.service';
import {ParametrosService} from '../services/parametros.service';
import {ConsultaSsiService} from '../services/consulta_ssi.service';
import {MenuItem, ConfirmationService, ConfirmEventType, MessageService,Message} from 'primeng/api';
//import { ToastrService } from 'ngx-toastr';
import {PpiPromafiModel} from "../models/ppi-promafi.model";
import {DistritosModel} from "../models/distritos.model";
import {DepartamentosModel} from "../models/departamentos.model";
import {ProyectosModel} from "../models/proyectos.model";

@Component({
    selector: 'app-ppi-promafi',
    templateUrl: './ppi-promafi.component.html',
    styleUrls: ['./ppi-promafi.component.css'],
    providers:[UsuariosService,ConfirmationService,MessageService,PpiPromafiService,ParametrosService,ConsultaSsiService]
})
export class PpiPromafiComponent implements OnInit {
    public token: string;
    
    public ppipromafi: Array<PpiPromafiModel>;
    public ppipromafiSeleccionado: PpiPromafiModel;
    public ppipromafiCloned: { [s: number]: PpiPromafiModel; } = {};
    public ppipromafiXLSX: Array<any>;
    
    public departamentos: Array<DepartamentosModel>=[];
    public distritos: Array<DistritosModel>=[];
    public distritosFiltrado: Array<DistritosModel>=[];
    public distritosFiltradoEdit: { [s: number]: Array<DistritosModel>; } = {};
    public proyectos: Array<ProyectosModel>=[];
    
    public items: MenuItem[];
    
    public displayModal: boolean=false;
    public displayModalGestion: boolean=false;
    
    public gestiones:Array<any>;
    public gestion: string='';
    
    public cabeceraModal: string;
    
    public error_nombre: boolean;
    public error_ci: boolean;
    public mensaje_error_ci: string;
    
    public modoedicion: boolean=false;
    public editar: boolean=false;
    
    activeIndex: number = 0;
    
    constructor(
        private _usuariosService: UsuariosService,
        private _confirmationService: ConfirmationService,
        private _messageService: MessageService,
        private _ppipromafiService: PpiPromafiService,
        private _parametrosService: ParametrosService,
        private _consultaSsiService: ConsultaSsiService
        //private _toastr: ToastrService
        ) {
            this.token = this._usuariosService.getToken();
            this.ppipromafiSeleccionado = {} as PpiPromafiModel;
    }

    ngOnInit(): void {
        this.items = [
            {label: 'Ver', icon: 'pi pi-fw pi-search', command: () => this.abrirPpiPromafi(this.ppipromafiSeleccionado)},
            {label: 'Eliminar', icon: 'pi pi-fw pi-times', command: () => this.eliminarPpiPromafi(this.ppipromafiSeleccionado)}
        ];
        
        this.gestiones=[];
        const currentYear  = new Date().getFullYear();
        this.gestion = String(currentYear);
        
        for(let g=2019;g<=currentYear;g++){
            let valor: string=String(g);
            /*
            if(g==currentYear){
                valor='';
            }
            */
            this.gestiones.push({
                'valor': valor,
                'gestion': g
            });
        }
        
        this._parametrosService.getDepartamentosData(this.token).subscribe(
            response =>{
                //console.log("VMG:");
                this.departamentos=response;
                //console.log(this.departamentos);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                //this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this._parametrosService.getDistritosData(this.token).subscribe(
            response =>{
                //console.log("VMG:");
                this.distritos=response;
                //console.log(this.distritos);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
            }
        );
        
        this._parametrosService.getProyectosData(this.token).subscribe(
            response =>{
                //console.log("VMG:");
                this.proyectos=response;
                //console.log(this.proyectos);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                //this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this.cargarPpiPromafi();
    }
    
    cargarPpiPromafi(){
        this.ppipromafi=[];
        this.ppipromafiXLSX=[];
        this._ppipromafiService.getPpiPromafiData(this.token).subscribe(
            response =>{
                //console.log("VMG:");
                this.ppipromafi=response;
                
                for (let pp = 0; pp < this.ppipromafi.length; pp++){
                    this.ppipromafiXLSX.push({
                        'Organziacion': this.ppipromafi[pp].organizacion,
                        'RUC': this.ppipromafi[pp].ruc,
                        'Proyecto': this.ppipromafi[pp].proyecto,
                        'Plan': this.ppipromafi[pp].plan,
                        'Nombre': this.ppipromafi[pp].nombre,
                        'Apellido': this.ppipromafi[pp].apellido,
                        'CI': this.ppipromafi[pp].ci,
                        'Fecha Nacimiento': this.ppipromafi[pp].fechanac,
                        'Edad': this.ppipromafi[pp].edad,
                        'Sexo': this.ppipromafi[pp].sexo,
                        'Sup HA': this.ppipromafi[pp].supha,
                        'Dependientes': this.ppipromafi[pp].dependientes,
                        'Celular': this.ppipromafi[pp].nocelular,
                        'Departamento': this.ppipromafi[pp].dptonombre,
                        'Distrito': this.ppipromafi[pp].dtonombre,
                        'Fecha obligado': this.ppipromafi[pp].fechaobligado,
                        'Latitud': this.ppipromafi[pp].latitud,
                        'Longitud': this.ppipromafi[pp].longitud,
                        'X': this.ppipromafi[pp].xxx,
                        'Y': this.ppipromafi[pp].yyy
                    })
                }
                
                this.ppipromafi.filter(ppipromafi => ppipromafi.fechanac != null).forEach(
                    ppipromafi => (ppipromafi.fechanac = new Date(ppipromafi.fechanac+"T00:00:00"))
                );
                
                this.ppipromafi.filter(ppipromafi => ppipromafi.fechaobligado != null).forEach(
                    ppipromafi => (ppipromafi.fechaobligado = new Date(ppipromafi.fechaobligado+"T00:00:00"))
                );
                
                
                
                
                console.log(this.ppipromafi);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
            }
        );
    }
    
    onRowEditInit(ppipromafi: PpiPromafiModel) {
        //console.log(vmg);
        this.ppipromafiCloned[ppipromafi.ppipromafiid] = {...ppipromafi};
        this.cargarDistritoEdit(ppipromafi);
    }
    
    onRowEditSave(ppipromafi: PpiPromafiModel) {
        this.error_nombre=false;
        if (ppipromafi.nombre.length==0){
            this.error_nombre=true;
        }
        this.error_ci=false;
        if(ppipromafi.ci==null){
            ppipromafi.ci='';
        }
        if (ppipromafi.ci.length==0){
            this.error_ci=true;
            this.mensaje_error_ci="EL campo CI es requerido";
        }else{
            for (let vv = 0; vv < this.ppipromafi.length; vv++){
                if (ppipromafi.ppipromafiid != this.ppipromafi[vv].ppipromafiid){
                    if (ppipromafi.ci === this.ppipromafi[vv].ci){
                        this.error_ci=true;
                        this.mensaje_error_ci='Ya existe el documento';
                    }
                }
            }
        }
        
        if (!this.error_nombre && !this.error_ci){
            this.editarDatos(ppipromafi);
        }else{
            var indicePpi = this.ppipromafi.findIndex(x => x.ppipromafiid === ppipromafi.ppipromafiid);
            this.ppipromafi[indicePpi] = this.ppipromafiCloned[ppipromafi.ppipromafiid];
            delete this.ppipromafiCloned[ppipromafi.ppipromafiid];
            if (this.error_nombre){
                this._messageService.add({severity:'error', summary: 'Error', detail: "El campo Nombre y Apellido es reuerido"});
            }
            if (this.error_ci){
                this._messageService.add({severity: 'error', summary: 'Error', detail: this.mensaje_error_ci});
            }
        }
        
        /*
        if (ppipromafi.nombre.length > 0) {
            //console.log(vmg);
            this.editarDatos(ppipromafi);
            
            //this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
        }else {
            return false;
            //this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid Price'});
        }
        */
    }
    
    onRowEditCancel(ppipromafi: PpiPromafiModel, index: number) {
        var indicePpiPromafi = this.ppipromafi.findIndex(x => x.ppipromafiid === ppipromafi.ppipromafiid);
        this.ppipromafi[indicePpiPromafi] = this.ppipromafiCloned[ppipromafi.ppipromafiid];
        delete this.ppipromafiCloned[ppipromafi.ppipromafiid];
    }
    
    abrirPpiPromafi(ppipromafi: PpiPromafiModel){
        this.modoedicion=false;
        this.cabeceraModal="Nuevo";
        if (ppipromafi.ppipromafiid>0){
            this.modoedicion=true;
            this.cabeceraModal="Editar";
            this.ppipromafiCloned[ppipromafi.ppipromafiid] = {...ppipromafi};
        }
        this.cargarDistrito(ppipromafi);
        this.error_nombre=false;
        this.error_ci=false;
        this.mensaje_error_ci='';
        this.displayModal = true;
    }
    
    cerrarVentana(){
        if (this.modoedicion && !this.editar){
            var indicePpiPromafi = this.ppipromafi.findIndex(x => x.ppipromafiid === this.ppipromafiSeleccionado.ppipromafiid);
            this.ppipromafi[indicePpiPromafi] = this.ppipromafiCloned[this.ppipromafiSeleccionado.ppipromafiid];
            delete this.ppipromafiCloned[this.ppipromafiSeleccionado.ppipromafiid];
        }
    }
    
    eliminarPpiPromafi(ppipromafi: PpiPromafiModel){
        this._confirmationService.confirm({
            message: 'Está seguro que desea eliminar a ' + ppipromafi.nombre+'?<br />ES PROBABLE QUE SOLO DESEE QUITARLO DE LA PRESENTE GESTION',
            header: 'Eliminar registro',
            icon: 'pi pi-info-circle',
            accept: () => {
                this._ppipromafiService.deletePpiPromafiData(this.token, ppipromafi.ppipromafiid).subscribe(
                    response =>{
                        if(response.codigo==200){
                            this.ppipromafi = this.ppipromafi.filter((vm) => vm.ppipromafiid !== ppipromafi.ppipromafiid);
                            this._messageService.add({severity:'info', summary:'Confirmado', detail:'Registro eliminado'});
                        }else{
                            this._messageService.add({severity:'error', summary: 'Error', detail: response.mensaje});
                        }
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                    }
                );
                
            },
            reject: (type) => {
                /*
                switch(type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
                    break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
                    break;
                }
                */
            }
        });
    }
    
    nuevoPpiPromafi(){
        const currentYear  = new Date().getFullYear();
        this.gestion = String(currentYear);
        this.ppipromafiSeleccionado={
            ppipromafiid: 0,
            organizacion: '',
            ruc: '',
            proyectoid: null,
            proyecto: '',
            plan: '',
            nombre: '',
            apellido: null,
            ci: '',
            fechanac: null,
            edad: 0,
            sexo: 'M',
            supha: 0,
            dependientes: 0,
            nocelular: '',
            dptoid: null,
            dptonombre: '',
            dptodtoid: null,
            dtonombre: '',
            fechaobligado: null,
            latitud: '',
            longitud: '',
            xxx: '',
            yyy: '',
            gestion: [this.gestion]
            
        };
        this.abrirPpiPromafi(this.ppipromafiSeleccionado);
    }
    
    agreagrGestion(){
        this.displayModalGestion=false;
        this._ppipromafiService.agregarGestionData(this.token, this.gestion).subscribe(
            response =>{
                //console.log(response);
                if(response.codigo==200){
                    this._messageService.add({severity:'success', summary: 'Exito', detail: response.mensaje});
                    this.cargarPpiPromafi();
                }else{
                    this._messageService.add({severity:'error', summary: 'Error', detail: response.mensaje});
                }
                
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
            }
        );
    }
    
    cambioDepartamento(ppipromafi: PpiPromafiModel){
        
        var indiceDpto = this.departamentos.findIndex(x => x.dptoid === ppipromafi.dptoid);
        if (this.ppipromafiSeleccionado.ppipromafiid>0){
            var indicePpiPromafi = this.ppipromafi.findIndex(x => x.ppipromafiid === ppipromafi.ppipromafiid);
            if(indiceDpto>=0){
                this.ppipromafi[indicePpiPromafi].dptonombre = this.departamentos[indiceDpto].dptonombre;
            }else{
                this.ppipromafi[indicePpiPromafi].dptonombre = null;
            }
            this.ppipromafi[indicePpiPromafi].dptodtoid=null;
            this.ppipromafi[indicePpiPromafi].dtonombre=null;
            if (ppipromafi.dptoid!=null){
                this.cargarDistrito(ppipromafi);
            }
        }
        if (this.ppipromafiSeleccionado.ppipromafiid==0){
            if(indiceDpto>=0){
                this.ppipromafiSeleccionado.dptonombre = this.departamentos[indiceDpto].dptonombre;
            }else{
                this.ppipromafiSeleccionado.dptonombre = null;
            }
            this.ppipromafiSeleccionado.dptodtoid=null;
            this.ppipromafiSeleccionado.dtonombre=null;
            if (this.ppipromafiSeleccionado.dptoid!=null){
                this.cargarDistrito(this.ppipromafiSeleccionado);
            }
        }
    }
    
    cambioDepartamentoEdit(ppipromafi: PpiPromafiModel){
        var indicePpiPromafi = this.ppipromafi.findIndex(x => x.ppipromafiid === ppipromafi.ppipromafiid);
        var indiceDpto = this.departamentos.findIndex(x => x.dptoid === ppipromafi.dptoid);

        if(indiceDpto>=0){
            this.ppipromafi[indicePpiPromafi].dptonombre = this.departamentos[indiceDpto].dptonombre;
        }else{
            this.ppipromafi[indicePpiPromafi].dptonombre = null;
        }
        this.ppipromafi[indicePpiPromafi].dptodtoid=null;
        this.ppipromafi[indicePpiPromafi].dtonombre=null;
        if (ppipromafi.dptoid!=null){
            this.cargarDistritoEdit(ppipromafi);
        }
    }
    
    cargarDistritoEdit(ppipromafi: PpiPromafiModel){
        this.distritosFiltradoEdit[ppipromafi.ppipromafiid]={} as any;
        let dptoid = ppipromafi.dptoid;

        this.distritosFiltradoEdit[ppipromafi.ppipromafiid] = this.distritos.filter(function(item){
            return item.dptoid==dptoid;
        });
    }
    
    cargarDistrito(ppipromafi: PpiPromafiModel){
        this.distritosFiltrado=[];
        let dptoid = ppipromafi.dptoid;
        this.distritosFiltrado = this.distritos.filter(function(item){
            return item.dptoid==dptoid;
        });
    }
    
    cambioDistrito(){
        
        var indiceDto = this.distritos.findIndex(x => (x.dptoid === this.ppipromafiSeleccionado.dptoid && x.dtoid === this.ppipromafiSeleccionado.dptodtoid));
        
        if (this.ppipromafiSeleccionado.ppipromafiid>0){
            var indicePpiPromafi = this.ppipromafi.findIndex(x => x.ppipromafiid === this.ppipromafiSeleccionado.ppipromafiid);
            if(indiceDto>=0){
                this.ppipromafi[indicePpiPromafi].dtonombre = this.distritos[indiceDto].dtonombre;
            }else{
                this.ppipromafi[indicePpiPromafi].dtonombre = null;
            }
        }
        
        if (this.ppipromafiSeleccionado.ppipromafiid==0){
            if(indiceDto>=0){
                this.ppipromafiSeleccionado.dtonombre = this.distritos[indiceDto].dtonombre;
            }else{
                this.ppipromafiSeleccionado.dtonombre = null;
            }
        }
            
    }
    
    calcularEdad(ppipromafi: PpiPromafiModel){
        var indicePpiPromafi = this.ppipromafi.findIndex(x => x.ppipromafiid === ppipromafi.ppipromafiid);
        if (ppipromafi.fechanac instanceof Date && !isNaN(ppipromafi.fechanac.valueOf())){
            if (ppipromafi.ppipromafiid==0){
                this.ppipromafiSeleccionado.edad=this.getYearDiffWithMonth(ppipromafi.fechanac);
            }else{
                this.ppipromafi[indicePpiPromafi].edad = this.getYearDiffWithMonth(ppipromafi.fechanac);
            }
        }else{
            if (ppipromafi.ppipromafiid==0){
                this.ppipromafiSeleccionado.edad=0;
            }else{
                this.ppipromafi[indicePpiPromafi].edad = 0;
            }
                
        }
    }
    /*
    cambioDepartamento(){
        var indicePpiPromafi = this.ppipromafi.findIndex(x => x.ppipromafiid === this.ppipromafiSeleccionado.ppipromafiid);
        var indiceDpto = this.departamentos.findIndex(x => x.dptoid === this.ppipromafiSeleccionado.dptoid);
        if(indiceDpto>=0){
            this.ppipromafi[indicePpiPromafi].dptonombre = this.departamentos[indiceDpto].dptonombre;
        }else{
            this.ppipromafi[indicePpiPromafi].dptonombre = null;
        }
    }
    */
    cambioProyecto(ppipromafi: PpiPromafiModel){
        var indicePpiPromafi = this.ppipromafi.findIndex(x => x.ppipromafiid === ppipromafi.ppipromafiid);
        var indiceProyecto = this.proyectos.findIndex(x => x.proyectoid === ppipromafi.proyectoid);

        if (indiceProyecto>=0){
            this.ppipromafi[indicePpiPromafi].proyecto = this.proyectos[indiceProyecto].proyecto;
        }else{
            this.ppipromafi[indicePpiPromafi].proyecto = null;
        }
    }
    /*
    calcularEdad(){
        var indicePpiPromafi = this.ppipromafi.findIndex(x => x.ppipromafiid === this.ppipromafiSeleccionado.ppipromafiid);
        if (this.ppipromafiSeleccionado.fechanac instanceof Date && !isNaN(this.ppipromafiSeleccionado.fechanac.valueOf())){
            this.ppipromafi[indicePpiPromafi].edad = this.getYearDiffWithMonth(this.ppipromafiSeleccionado.fechanac);
        }else{
            this.ppipromafi[indicePpiPromafi].edad = 0;
        }
    }
    */
    getYearDiffWithMonth(startDate: Date) {
        var fechaactual = new Date();
        const ms = fechaactual.getTime() - startDate.getTime();
        const date = new Date(ms);
        return Math.abs(date.getUTCFullYear() - 1970);
    }
    
    buscarCI(){
        this.error_ci=false;
        if (this.ppipromafiSeleccionado.ci==null){
            this.ppipromafiSeleccionado.ci='';
        }
        if (this.ppipromafiSeleccionado.ci.length==0){
            this.error_ci=true;
            this.mensaje_error_ci='Campo requerido';
        }
        
        if (!this.error_ci){
            this._consultaSsiService.getConsultaSsiData(this.token, this.ppipromafiSeleccionado.ci).subscribe(
                response =>{
                    //console.log("VMG:");
                    console.log(response);
                    if(response.nombre.length>0){
                        this.ppipromafiSeleccionado.nombre=response.nombre;
                        this.ppipromafiSeleccionado.apellido=response.apellido;
                        this.ppipromafiSeleccionado.fechanac=new Date(response.fecha_nacimiento+"T00:00:00");
                        this.ppipromafiSeleccionado.sexo=response.sexo;
                        this.calcularEdad(this.ppipromafiSeleccionado);
                    }
                        
                    //console.log(this.distritos);
                },
                error=>{
                    let mensaje_error=<any>error;
                    this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                }
            );
        }
    }
    
    guardarDatos(){
        this.editar=false;
        this.error_nombre=false;
        if (this.ppipromafiSeleccionado.nombre.length==0){
            this.error_nombre=true;
        }
        
        this.error_ci=false;
        if(this.ppipromafiSeleccionado.ci==null){
            this.ppipromafiSeleccionado.ci='';
        }
        if (this.ppipromafiSeleccionado.ci.length==0){
            this.error_ci=true;
            this.mensaje_error_ci='Campo requerido';
        }else{
            if (this.ppipromafiSeleccionado.ppipromafiid==0){
                var indicePpi = this.ppipromafi.findIndex(x => x.ci === this.ppipromafiSeleccionado.ci);
                if (indicePpi >= 0){
                    this.error_ci=true;
                    this.mensaje_error_ci='Ya existe el documento';
                }
            }else{
                for (let vv = 0; vv < this.ppipromafi.length; vv++){
                    if (this.ppipromafiSeleccionado.ppipromafiid != this.ppipromafi[vv].ppipromafiid){
                        if (this.ppipromafiSeleccionado.ci === this.ppipromafi[vv].ci){
                            this.error_ci=true;
                            this.mensaje_error_ci='Ya existe el documento';
                        }
                    }
                }
            }
                
        }
        
        
        
        
        if (!this.error_nombre && !this.error_ci){
            if (this.ppipromafiSeleccionado.ppipromafiid==0){
                //console.log(this.vmgSeleccionado);
                this._ppipromafiService.addPpiPromafiData(this.token, this.ppipromafiSeleccionado).subscribe(
                    response =>{
                        if(response.codigo==200){
                            this._messageService.add({severity:'success', summary: 'Exito', detail: response.mensaje});
                            this.cargarPpiPromafi();
                        }else{
                            this._messageService.add({severity:'error', summary: 'Error', detail: response.mensaje});
                        }
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                    }
                );
            }else{
                this.editar=true;
                this.editarDatos(this.ppipromafiSeleccionado);
                //console.log()
            }
            this.displayModal=false;
        }
    }
    
    editarDatos(ppipromafi: PpiPromafiModel){
        console.log(ppipromafi);
        this._ppipromafiService.savePpiPromafiData(this.token, ppipromafi.ppipromafiid, ppipromafi).subscribe(
            response =>{
                console.log(response);
                if(response.codigo==200){
                    this._messageService.add({severity:'success', summary: 'Exito', detail: response.mensaje});
                    delete this.ppipromafiCloned[ppipromafi.ppipromafiid];
                }else{
                    this._messageService.add({severity:'error', summary: 'Error', detail: response.mensaje});
                }
                    
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
            }
        );
    }
    
    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.ppipromafiXLSX);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "PpiPromafi");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import("file-saver").then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

}
