<!--<menu style="margin-top: 0px; padding-left: 0px"></menu>-->
<!-- Page Wrapper -->
<div id="wrapper">
    <!-- Sidebar -->
    <app-menu-lateral style="background-color: #224abe;"></app-menu-lateral>
    <!-- End of Sidebar -->
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
            <!-- Topbar -->
            <app-menu-superior></app-menu-superior>
            <!-- End of Topbar -->
            <!-- Begin Page Content -->
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">Inicio</li>
                    </ol>
                </nav>
                
                <p-tabView>
                    <p-tabPanel header="Asistencia Tecnica">
                        <div class="row">
                            <div class="col-xl-4 col-md-6 mb-4">
                                <div class="card border-left-primary shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Asistencia t&eacute;cnica</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                                    Hombres: {{at_total_M | number:'1.0-0'}}<br />
                                                    Mujeres: {{at_total_F | number:'1.0-0'}}<br />
                                                    Total: {{asistencia_tecnica_distinct.length | number:'1.0-0'}}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-users fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-6">
                                <div class="card shadow mb-4">
                                    <div
                                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 class="m-0 font-weight-bold text-primary">Asistencia t&eacute;nica por Base de Datos</h6>
                                        <!--
                                        <div class="dropdown no-arrow">
                                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                                aria-labelledby="dropdownMenuLink">
                                                <div class="dropdown-header">Dropdown Header:</div>
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        -->
                                    </div>

                                    <div class="card-body">
                                        <p-chart type="doughnut" [data]="at1_donutData" [options]="at1_donutOptions" [style]="{'width': '40%'}"></p-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Area Chart -->
                            <div class="col-xl-12 col-lg-12">
                                <div class="card shadow mb-4">
                                    <!-- Card Header - Dropdown -->

                                    <div
                                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 class="m-0 font-weight-bold text-primary">Asistencia T&eacute;cnica por departamento y sexo</h6>
                                        <!--
                                        <div class="dropdown no-arrow">
                                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                                aria-labelledby="dropdownMenuLink">
                                                <div class="dropdown-header">Dropdown Header:</div>
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        -->
                                    </div>
                                    <!-- Card Body -->
                                    <div class="card-body">
                                            <p-chart type="horizontalBar" [data]="at1Data" [options]="at1Options"></p-chart>
                                            <!--<p-chart type="bar" [data]="at1Data" [options]="at1Options"></p-chart>-->

                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="row">
                            <!-- Area Chart -->
                            <div class="col-xl-12 col-lg-12">
                                <div class="card shadow mb-4">
                                    <!-- Card Header - Dropdown -->

                                    <div
                                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 class="m-0 font-weight-bold text-primary">Asistencia T&eacute;cnica por distrito y sexo</h6>
                                        <!--
                                        <div class="dropdown no-arrow">
                                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                                aria-labelledby="dropdownMenuLink">
                                                <div class="dropdown-header">Dropdown Header:</div>
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        -->
                                    </div>
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <h5>Departamento</h5>
                                        <p-dropdown [options]="asistencia_tecnica_by_dpto_array" [(ngModel)]="departamento_seleccionado"  inputId="departamento_seleccionado"  placeholder="[Seleccione]" optionLabel="key" optionValue="key" [filter]="true" [showClear]="true" (onChange)="cargarCuadroAT()"></p-dropdown>
                                        <p-chart *ngIf="departamento_seleccionado!=null" type="horizontalBar" [data]="at1Data_dto" [options]="at1Options_dto"></p-chart>
                                        <!--<p-chart type="bar" [data]="at1Data" [options]="at1Options"></p-chart>-->

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div class="row">
                            <!-- Area Chart -->
                            <div class="col-xl-12 col-lg-12">
                                <div class="card shadow mb-4">
                                    <!-- Card Header - Dropdown -->

                                    <div
                                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 class="m-0 font-weight-bold text-primary">Asistencia T&eacute;cnica por Rubro</h6>
                                    </div>
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <h5>Departamento</h5>
                                        <p-dropdown [options]="departamentos_agricola" [(ngModel)]="departamento_seleccionado_asistencia_tecnica_agricola"  inputId="departamento_seleccionado_asistencia_tecnica_agricola"  placeholder="[Seleccione]" optionLabel="key" optionValue="key" [filter]="true" [showClear]="true" (onChange)="cambioDepartamentoATRubro()"></p-dropdown>
                                        <h5>Distrito</h5>
                                        <p-dropdown [options]="distritos_agricola" [(ngModel)]="distrito_seleccionado_asistencia_tecnica_agricola"  inputId="distrito_seleccionado_asistencia_tecnica_agricola"  placeholder="[Seleccione]" optionLabel="key" optionValue="key" [filter]="true" [showClear]="true" (onChange)="cargarCadroATRubro()" [disabled]="departamento_seleccionado_asistencia_tecnica_agricola==null"></p-dropdown>
                                        <p-chart *ngIf="asistencia_tecnica_agricola_data" type="horizontalBar" [data]="asistencia_tecnica_agricola_data" [options]="asistencia_tecnica_agricola_options"></p-chart>

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </p-tabPanel>
                    <p-tabPanel header="Inversiones">
                        <div class="row">
                            <div class="col-xl-4 col-md-6 mb-4">
                                <div class="card border-left-success shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                    Inversiones</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800">
                                                    Hombres: {{inv_total_M | number:'1.0-0'}}<br />
                                                    Mujeres: {{inv_total_F | number:'1.0-0'}}<br />
                                                    Total: {{inversiones_distinct.length | number:'1.0-0'}}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-6">
                                <div class="card shadow mb-4">
                                    <div
                                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 class="m-0 font-weight-bold text-primary">Inversiones por Base de Datos</h6>
                                    </div>

                                    <div class="card-body">
                                        <p-chart type="doughnut" [data]="inv1_donutData" [options]="inv1_donutOptions" [style]="{'width': '40%'}"></p-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- Area Chart -->
                            <div class="col-xl-12 col-lg-12">
                                <div class="card shadow mb-4">
                                    <!-- Card Header - Dropdown -->

                                    <div
                                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 class="m-0 font-weight-bold text-primary">Inversiones por departamento y sexo</h6>
                                    </div>
                                    <!-- Card Body -->
                                    <div class="card-body">
                                            <p-chart type="horizontalBar" [data]="inv1Data" [options]="inv1Options"></p-chart>
                                            <!--<p-chart type="bar" [data]="at1Data" [options]="at1Options"></p-chart>-->

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!-- Area Chart -->
                            <div class="col-xl-12 col-lg-12">
                                <div class="card shadow mb-4">
                                    <!-- Card Header - Dropdown -->

                                    <div
                                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 class="m-0 font-weight-bold text-primary">Inversiones por distrito y sexo</h6>
                                    </div>
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <h5>Departamento</h5>
                                        <p-dropdown [options]="inversiones_by_dpto_array" [(ngModel)]="departamento_seleccionado_inv"  inputId="departamento_seleccionado_inv"  placeholder="[Seleccione]" optionLabel="key" optionValue="key" [filter]="true" [showClear]="true" (onChange)="cargarCuadroINV()"></p-dropdown>
                                        <p-chart *ngIf="departamento_seleccionado_inv!=null" type="horizontalBar" [data]="inv1Data_dto" [options]="inv1Options_dto"></p-chart>
                                        <!--<p-chart type="bar" [data]="at1Data" [options]="at1Options"></p-chart>-->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                </p-tabView>
                
                <!--
                <div class="row">
                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-info shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Tareas
                                        </div>
                                        <div class="row no-gutters align-items-center">
                                            <div class="col-auto">
                                                <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
                                            </div>
                                            <div class="col">
                                                <div class="progress progress-sm mr-2">
                                                    <div class="progress-bar bg-info" role="progressbar"
                                                        style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-md-6 mb-4">
                        <div class="card border-left-warning shadow h-100 py-2">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                        <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            Proyectos pendientes</div>
                                        <div class="h5 mb-0 font-weight-bold text-gray-800">18</div>
                                    </div>
                                    <div class="col-auto">
                                        <i class="fas fa-comments fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                -->
                
                
                
                
                
                
                        
                
                <!--
                <div class="row">

                    <div class="col-lg-6 mb-4">

                        <div class="card shadow mb-4">
                            <div class="card-header py-3">
                                <h6 class="m-0 font-weight-bold text-primary">Ejecusion de Proyectos</h6>
                            </div>
                            <div class="card-body">
                                <h4 class="small font-weight-bold">Proyecto A <span
                                        class="float-right">20%</span></h4>
                                <div class="progress mb-4">
                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 20%"
                                        aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <h4 class="small font-weight-bold">Proyecto B <span
                                        class="float-right">80%</span></h4>
                                <div class="progress mb-4">
                                    <div class="progress-bar bg-warning" role="progressbar" style="width: 80%"
                                        aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <h4 class="small font-weight-bold">Proyecto C <span
                                        class="float-right">50%</span></h4>
                                <div class="progress mb-4">
                                    <div class="progress-bar" role="progressbar" style="width: 50%"
                                        aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <h4 class="small font-weight-bold">Proyecto D <span
                                        class="float-right">20%</span></h4>
                                <div class="progress mb-4">
                                    <div class="progress-bar bg-info" role="progressbar" style="width: 20%"
                                        aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <h4 class="small font-weight-bold">Proyecto E <span
                                        class="float-right">Completo!</span></h4>
                                <div class="progress">
                                    <div class="progress-bar bg-success" role="progressbar" style="width: 100%"
                                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                -->
                
            </div>
        </div>
    </div>
</div>