import { Component, OnInit } from '@angular/core';
import {UsuariosService} from '../services/usuarios.service';
import {VmgService} from '../services/vmg.service';
import {ParametrosService} from '../services/parametros.service';
import {ConsultaSsiService} from '../services/consulta_ssi.service';
import {MenuItem, ConfirmationService, ConfirmEventType, MessageService,Message} from 'primeng/api';
//import { ToastrService } from 'ngx-toastr';
import {VmgModel} from "../models/vmg.model";
import {DistritosModel} from "../models/distritos.model";
import {DepartamentosModel} from "../models/departamentos.model";
import {AgricolaModel} from "../models/agricola.model";
import {PecuarioModel} from "../models/pecuario.model";
import {GestionModel} from "../models/gestion.model";

@Component({
    selector: 'app-vmg',
    templateUrl: './vmg.component.html',
    styleUrls: ['./vmg.component.css'],
    providers:[UsuariosService,ConfirmationService,MessageService,VmgService,ParametrosService,ConsultaSsiService]
})
export class VmgComponent implements OnInit {
    public token: string;
    
    public vmg: Array<VmgModel>;
    public vmgSeleccionado: VmgModel;
    public vmgCloned: { [s: number]: VmgModel; } = {};
    public vmgXLSX: Array<any>;
    
    public tipoproyectos: Array<any>;
    public departamentos: Array<DepartamentosModel>=[];
    public distritos: Array<DistritosModel>=[];
    public distritosFiltrado: Array<DistritosModel>=[];
    public distritosFiltradoEdit: { [s: number]: Array<DistritosModel>; } = {};
    public agricolaCloned: { [s: number]: Array<AgricolaModel>; } = {};
    public pecuarioCloned: { [s: number]: Array<PecuarioModel>; } = {};
    //public gestionCloned: { [s: number]: string[]; } = {};
    public cultivos: Array<any>;
    public pecuarios: Array<any>;
    //public distritosFiltradoEdit: Array<DistritosModel>=[];
    //public distritosFiltradoEdit: Array<Array<DistritosModel>>;
    
    public items: MenuItem[];
    
    activeIndex: number = 0;
    
    public displayModal: boolean=false;
    public displayModalGestion: boolean=false;
    public displayModalRubros: boolean=false;
    
    public gestiones:Array<any>;
    public gestion: string='';
    
    public gestion_actual: string;
    
    public cabeceraModal: string;
    
    public error_nombre: boolean;
    public error_ci: boolean;
    public mensaje_error_ci: string;
    public error_tipoproyecto: boolean;
    
    public modoedicion: boolean=false;
    public editar: boolean=false;
    
    /*
    public sexo: string;
    public ci: string;
    public fechanac: string;
    public dptoid: number=null;
    public dptodtoid: number=null;
    public organizacion: string;
    */
    
    
    

    constructor(
        private _usuariosService: UsuariosService,
        private _confirmationService: ConfirmationService,
        private _messageService: MessageService,
        private _vmgService: VmgService,
        private _parametrosService: ParametrosService,
        private _consultaSsiService: ConsultaSsiService
        //private _toastr: ToastrService
        ) {
            this.token = this._usuariosService.getToken();
            this.gestion_actual = this._usuariosService.getGestion();
            if(this.gestion_actual==''){
                this.gestion_actual = String(new Date().getFullYear());
            }
            this.vmgSeleccionado = {} as VmgModel;
    }

    ngOnInit(): void {
        
        this.items = [
            {label: 'Ver', icon: 'pi pi-fw pi-search', command: () => this.abrirVmg(this.vmgSeleccionado)},
            {label: 'Eliminar', icon: 'pi pi-fw pi-times', command: () => this.eliminarVmg(this.vmgSeleccionado)}
        ];
        
        this.gestiones=[];
        const currentYear  = new Date().getFullYear();
        this.gestion = String(currentYear);
        
        for(let g=2019;g<=currentYear;g++){
            let valor: string=String(g);
            /*
            if(g==currentYear){
                valor='';
            }
            */
            this.gestiones.push({
                'valor': valor,
                'gestion': g
            });
        }
        
        this._parametrosService.getTipoProyectosData(this.token).subscribe(
            response =>{
                //console.log("VMG:");
                this.tipoproyectos=response;
                //console.log(this.departamentos);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                //this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this._parametrosService.getDepartamentosData(this.token).subscribe(
            response =>{
                //console.log("VMG:");
                this.departamentos=response;
                //console.log(this.departamentos);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                //this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this._parametrosService.getDistritosData(this.token).subscribe(
            response =>{
                //console.log("VMG:");
                this.distritos=response;
                //console.log(this.distritos);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
            }
        );
        
        this._parametrosService.getCultivosData(this.token).subscribe(
            response =>{
                //console.log("VMG:");
                this.cultivos=response;
                //console.log(this.departamentos);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                //this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this._parametrosService.getPecuariosData(this.token).subscribe(
            response =>{
                //console.log("VMG:");
                this.pecuarios=response;
                //console.log(this.departamentos);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                //this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this.cargarVMG();
    }
    
    cargarVMG(){
        this.vmg=[];
        this.vmgXLSX=[];
        console.log(this.gestion_actual)
        this._vmgService.getVmgData(this.token, this.gestion_actual).subscribe(
            response_vmg =>{
                //console.log("VMG:");
                this.vmg=response_vmg;
                
                for (let vv = 0; vv < this.vmg.length; vv++){
                    this.vmgXLSX.push({
                        'Nombre y Apellido': this.vmg[vv].nombre + ' ' + this.vmg[vv].apellido,
                        'Sexo': this.vmg[vv].sexo,
                        'Fecha Nacimiacimiento': this.vmg[vv].fechanac,
                        'Edad': this.vmg[vv].edad,
                        'CI': this.vmg[vv].ci,
                        'Departamento': this.vmg[vv].dptonombre,
                        'Distrito': this.vmg[vv].dtonombre
                    });
                }
                
                this.vmg.filter(vmg => vmg.fechanac != null).forEach(
                    vmg => (vmg.fechanac = new Date(vmg.fechanac+"T00:00:00"))
                );
                
                
                
                
                
                console.log(this.vmg);
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
            }
        );
    }
    
    onRowEditInit(vmg: VmgModel) {
        //console.log(vmg);
        this.vmgCloned[vmg.vmgid] = {...vmg};
        this.cargarDistritoEdit(vmg);
    }
    
    onRowEditSave(vmg: VmgModel) {
        this.error_nombre=false;
        if (vmg.nombre.length==0){
            this.error_nombre=true;
        }
        this.error_ci=false;
        if(vmg.ci==null){
            vmg.ci='';
        }
        if (vmg.ci.length==0){
            this.error_ci=true;
            this.mensaje_error_ci="EL campo CI es requerido";
        }else{
            for (let vv = 0; vv < this.vmg.length; vv++){
                if (vmg.vmgid != this.vmg[vv].vmgid){
                    if (vmg.ci === this.vmg[vv].ci && vmg.idtipoproyecto === this.vmg[vv].idtipoproyecto){
                        this.error_ci=true;
                        this.mensaje_error_ci='Ya existe el documento para el proyecto seleccionado';
                    }
                }
            }
        }
        
        if (!this.error_nombre && !this.error_ci){
            this.editarDatos(vmg);
        }else{
            var indiceVmg = this.vmg.findIndex(x => x.vmgid === vmg.vmgid);
            this.vmg[indiceVmg] = this.vmgCloned[vmg.vmgid];
            delete this.vmgCloned[vmg.vmgid];
            if (this.error_nombre){
                this._messageService.add({severity:'error', summary: 'Error', detail: "El campo Nombre y Apellido es reuerido"});
            }
            if (this.error_ci){
                this._messageService.add({severity: 'error', summary: 'Error', detail: this.mensaje_error_ci});
            }
        }
        /*
        if (vmg.nombre.length > 0) {
            //console.log(vmg);
            
            
            //this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
        }else {
            return false;
            //this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid Price'});
        }
        */
    }

    onRowEditCancel(vmg: VmgModel) {
        var indiceVmg = this.vmg.findIndex(x => x.vmgid === vmg.vmgid);
        this.vmg[indiceVmg] = this.vmgCloned[vmg.vmgid];
        delete this.vmgCloned[vmg.vmgid];
    }
    
    abrirVmg(vmg: VmgModel){
        this.activeIndex = 0;
        this.modoedicion=false;
        this.cabeceraModal="Nuevo";
        if(vmg.vmgid>0){
            this.modoedicion=true;
            this.cabeceraModal="Editar";
            this.vmgCloned[vmg.vmgid] = {...vmg};
            this.agricolaCloned[vmg.vmgid] = [...vmg.agricola];
            this.pecuarioCloned[vmg.vmgid] = [...vmg.pecuario];
        }
        this.cargarDistrito();
        this.error_nombre=false;
        this.error_ci=false;
        this.mensaje_error_ci='';
        this.error_tipoproyecto=false;
        this.displayModal = true;
    }
    
    cerrarVentana(){
        if (this.modoedicion && !this.editar){
            var indiceVmg = this.vmg.findIndex(x => x.vmgid === this.vmgSeleccionado.vmgid);
            this.vmg[indiceVmg] = this.vmgCloned[this.vmgSeleccionado.vmgid];
            this.vmg[indiceVmg].agricola = this.agricolaCloned[this.vmgSeleccionado.vmgid];
            this.vmg[indiceVmg].pecuario = this.pecuarioCloned[this.vmgSeleccionado.vmgid];
            delete this.vmgCloned[this.vmgSeleccionado.vmgid];
            delete this.agricolaCloned[this.vmgSeleccionado.vmgid];
            delete this.pecuarioCloned[this.vmgSeleccionado.vmgid];
        }
    }
    
    eliminarVmg(vmg: VmgModel){
        //console.log(vmg);
        this._confirmationService.confirm({
            message: 'Está seguro que desea eliminar a ' + vmg.nombre+'?<br />ES PROBABLE QUE SOLO DESEE QUITARLO DE LA PRESENTE GESTION',
            header: 'Eliminar registro',
            icon: 'pi pi-info-circle',
            accept: () => {
                this._vmgService.deleteVmgData(this.token, vmg.vmgid).subscribe(
                    response =>{
                        if(response.codigo==200){
                            this.vmg = this.vmg.filter((vm) => vm.vmgid !== vmg.vmgid);
                            this._messageService.add({severity:'info', summary:'Confirmado', detail:'Registro eliminado'});
                        }else{
                            this._messageService.add({severity:'error', summary: 'Error', detail: response.mensaje});
                        }
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                    }
                );
                
            },
            reject: (type) => {
                /*
                switch(type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
                    break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
                    break;
                }
                */
            }
        });
        //console.log(vmg);
    }
    
    nuevoVmg(){
        const currentYear  = new Date().getFullYear();
        this.gestion = String(currentYear);
        this.vmgSeleccionado={
            vmgid: 0,
            nombre: '',
            apellido: null,
            fechanac: null,
            edad: 0,
            sexo: 'M',
            ci: '',
            idtipoproyecto: 1,
            tipoproyecto: 'VMG',
            dptoid: null,
            dptonombre: '',
            dptodtoid: null,
            dtonombre: '',
            organizacionruc: null,
            organizacion: '',
            agricola: [],
            pecuario: [],
            gestion: [this.gestion]
        };
        this.abrirVmg(this.vmgSeleccionado);
    }
    
    agreagrGestion(){
        this.displayModalGestion=false;
        this._vmgService.agregarGestionData(this.token, this.gestion).subscribe(
            response =>{
                //console.log(response);
                if(response.codigo==200){
                    this._messageService.add({severity:'success', summary: 'Exito', detail: response.mensaje});
                    this.cargarVMG();
                }else{
                    this._messageService.add({severity:'error', summary: 'Error', detail: response.mensaje});
                }
                
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
            }
        );
    }
    
    agreagrRubros(){
        this.displayModalRubros=false;
        this._vmgService.copiarRubrosData(this.token, this.gestion_actual, this.gestion).subscribe(
            response =>{
                if(response.codigo==200){
                    this._messageService.add({severity:'success', summary: 'Exito', detail: response.mensaje});
                    this.cargarVMG();
                }else{
                    this._messageService.add({severity:'error', summary: 'Error', detail: response.mensaje});
                }
                
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
            }
        );
        
    }
    
    cambioDepartamentoEdit(vmg: VmgModel){
        var indiceVmg = this.vmg.findIndex(x => x.vmgid === vmg.vmgid);
        var indiceDpto = this.departamentos.findIndex(x => x.dptoid === vmg.dptoid);

        if(indiceDpto>=0){
            this.vmg[indiceVmg].dptonombre = this.departamentos[indiceDpto].dptonombre;
        }else{
            this.vmg[indiceVmg].dptonombre = null;
        }
        this.vmg[indiceVmg].dptodtoid=null;
        this.vmg[indiceVmg].dtonombre=null;
        if (vmg.dptoid!=null){
            this.cargarDistritoEdit(vmg);
        }
    }
    
    cargarDistritoEdit(vmg: VmgModel){
        this.distritosFiltradoEdit[vmg.vmgid]={} as any;
        let dptoid = vmg.dptoid;

        this.distritosFiltradoEdit[vmg.vmgid] = this.distritos.filter(function(item){
            return item.dptoid==dptoid;
        });
    }
    
    cambioTipoProyectoEdit(vmg: VmgModel){
        var indiceVmg = this.vmg.findIndex(x => x.vmgid === vmg.vmgid);
        var indiceTipoProyecto = this.tipoproyectos.findIndex(x => (x.idtipoproyecto === vmg.idtipoproyecto));

        if(indiceTipoProyecto>=0){
            this.vmg[indiceVmg].tipoproyecto = this.tipoproyectos[indiceTipoProyecto].tipoproyecto;
        }else{
            this.vmg[indiceVmg].tipoproyecto = null;
        }
    }
    
    cambioDistritoEdit(vmg: VmgModel){
        var indiceVmg = this.vmg.findIndex(x => x.vmgid === vmg.vmgid);
        var indiceDto = this.distritos.findIndex(x => (x.dptoid === vmg.dptoid && x.dtoid === vmg.dptodtoid));

        if(indiceDto>=0){
            this.vmg[indiceVmg].dtonombre = this.distritos[indiceDto].dtonombre;
        }else{
            this.vmg[indiceVmg].dtonombre = null;
        }
    }
    
    calcularEdadEdit(vmg: VmgModel){
        var indiceVmg = this.vmg.findIndex(x => x.vmgid === vmg.vmgid);
        if (vmg.fechanac instanceof Date && !isNaN(vmg.fechanac.valueOf())){
            this.vmg[indiceVmg].edad = this.getYearDiffWithMonth(vmg.fechanac);
        }else{
            this.vmg[indiceVmg].edad = 0;
        }
    }
    
    cambioDepartamento(){
        //console.log(this.vmgSeleccionado);
        var indiceDpto = this.departamentos.findIndex(x => x.dptoid === this.vmgSeleccionado.dptoid);
        if (this.vmgSeleccionado.vmgid>0){
            var indiceVmg = this.vmg.findIndex(x => x.vmgid === this.vmgSeleccionado.vmgid);
            if(indiceDpto>=0){
                this.vmg[indiceVmg].dptonombre = this.departamentos[indiceDpto].dptonombre;
            }else{
                this.vmg[indiceVmg].dptonombre = null;
            }
        }
        if(this.vmgSeleccionado.vmgid==0){
            if(indiceDpto>=0){
                this.vmgSeleccionado.dptonombre=this.departamentos[indiceDpto].dptonombre;
            }else{
                this.vmgSeleccionado.dptonombre=null;
            }
        }
        this.vmgSeleccionado.dptodtoid=null;
        this.vmgSeleccionado.dtonombre=null;
        if (this.vmgSeleccionado.dptoid!=null){
            this.cargarDistrito();
        }
    }
    
    cargarDistrito(){
        this.distritosFiltrado=[];
        let dptoid = this.vmgSeleccionado.dptoid;
        this.distritosFiltrado = this.distritos.filter(function(item){
            return item.dptoid==dptoid;
        });
    }
    
    cambioDistrito(){
        
        var indiceDto = this.distritos.findIndex(x => (x.dptoid === this.vmgSeleccionado.dptoid && x.dtoid === this.vmgSeleccionado.dptodtoid));
        
        if (this.vmgSeleccionado.vmgid>0){
            var indiceVmg = this.vmg.findIndex(x => x.vmgid === this.vmgSeleccionado.vmgid);
            if(indiceDto>=0){
                this.vmg[indiceVmg].dtonombre = this.distritos[indiceDto].dtonombre;
            }else{
                this.vmg[indiceVmg].dtonombre = null;
            }
        }
        
        if (this.vmgSeleccionado.vmgid==0){
            if(indiceDto>=0){
                this.vmgSeleccionado.dtonombre = this.distritos[indiceDto].dtonombre;
            }else{
                this.vmgSeleccionado.dtonombre = null;
            }
        }
            
    }
    
    cambioTipoProyecto(){
        var indiceTipoProyecto = this.tipoproyectos.findIndex(x => (x.idtipoproyecto === this.vmgSeleccionado.idtipoproyecto));
        
        if (this.vmgSeleccionado.vmgid>0){
            var indiceVmg = this.vmg.findIndex(x => x.vmgid === this.vmgSeleccionado.vmgid);
            if(indiceTipoProyecto>=0){
                this.vmg[indiceVmg].tipoproyecto = this.tipoproyectos[indiceTipoProyecto].tipoproyecto;
            }else{
                this.vmg[indiceVmg].tipoproyecto = null;
            }
        }
        
        if (this.vmgSeleccionado.vmgid==0){
            if(indiceTipoProyecto>=0){
                this.vmgSeleccionado.tipoproyecto = this.tipoproyectos[indiceTipoProyecto].tipoproyecto;
            }else{
                this.vmgSeleccionado.tipoproyecto = null;
            }
        }
    }
    
    calcularEdad(){
        var indiceVmg = this.vmg.findIndex(x => x.vmgid === this.vmgSeleccionado.vmgid);
        if (this.vmgSeleccionado.fechanac instanceof Date && !isNaN(this.vmgSeleccionado.fechanac.valueOf())){
            if (this.vmgSeleccionado.vmgid==0){
                this.vmgSeleccionado.edad=this.getYearDiffWithMonth(this.vmgSeleccionado.fechanac);
            }else{
                this.vmg[indiceVmg].edad = this.getYearDiffWithMonth(this.vmgSeleccionado.fechanac);
            }
        }else{
            if (this.vmgSeleccionado.vmgid==0){
                this.vmgSeleccionado.edad=0;
            }else{
                this.vmg[indiceVmg].edad = 0;
            }
        }
    }
    
    
    
    getYearDiffWithMonth(startDate: Date) {
        var fechaactual = new Date();
        const ms = fechaactual.getTime() - startDate.getTime();
        const date = new Date(ms);
        return Math.abs(date.getUTCFullYear() - 1970);
    }
    
    buscarCI(){
        this.error_ci=false;
        if(this.vmgSeleccionado.ci==null){
            this.vmgSeleccionado.ci='';
        }
        if (this.vmgSeleccionado.ci.length==0){
            this.error_ci=true;
            this.mensaje_error_ci='Campo requerido';
        }
        
        if (!this.error_ci){
            this._consultaSsiService.getConsultaSsiData(this.token,this.vmgSeleccionado.ci).subscribe(
                response =>{
                    //console.log("VMG:");
                    console.log(response);
                    if(response.nombre.length>0){
                        this.vmgSeleccionado.nombre=response.nombre+' '+response.apellido;
                        this.vmgSeleccionado.apellido=response.apellido;
                        this.vmgSeleccionado.fechanac=new Date(response.fecha_nacimiento+"T00:00:00");
                        this.vmgSeleccionado.sexo=response.sexo;
                        this.calcularEdad();
                    }
                        
                    //console.log(this.distritos);
                },
                error=>{
                    let mensaje_error=<any>error;
                    this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                }
            );
        }
    }
    
    nuevoAgricola(){
        this.vmgSeleccionado.agricola.push({
            idmovimiento_agricola: 0,
            id_cultivo: null,
            cultivo: '',
            total_sup: 0
        });
    }
    
    nuevoPecuario(){
        this.vmgSeleccionado.pecuario.push({
            idmovimiento_pecuario: 0,
            id_rubro: null,
            rubro_pecuario: '',
            existencia: 0
        });
    }
    
    eliminarAgricola(indexAgricola: number){
        this.vmgSeleccionado.agricola.splice(indexAgricola, 1);
    }
    
    eliminarPecuario(indexPecuario: number){
        this.vmgSeleccionado.pecuario.splice(indexPecuario, 1);
    }
    
    guardarDatos(){
        this.editar=false;
        this.error_nombre=false;
        if (this.vmgSeleccionado.nombre.length==0){
            this.error_nombre=true;
        }
        
        this.error_tipoproyecto=false;
        if (this.vmgSeleccionado.idtipoproyecto == null || this.vmgSeleccionado.idtipoproyecto==0){
            this.error_tipoproyecto=true;
        }
        
        this.error_ci=false;
        if(this.vmgSeleccionado.ci==null){
            this.vmgSeleccionado.ci='';
        }
        if (this.vmgSeleccionado.ci.length==0){
            this.error_ci=true;
            this.mensaje_error_ci='Campo requerido';
        }else{
            if(this.vmgSeleccionado.vmgid==0){
                var indiceVmg = this.vmg.findIndex(x => (x.ci === this.vmgSeleccionado.ci && x.idtipoproyecto === this.vmgSeleccionado.idtipoproyecto));
                if (indiceVmg >= 0){
                    this.error_ci=true;
                    this.mensaje_error_ci='Ya existe para el proyecto seleccionado';
                }
            }else{
                for (let vv = 0; vv < this.vmg.length; vv++){
                    if (this.vmgSeleccionado.vmgid != this.vmg[vv].vmgid){
                        if (this.vmgSeleccionado.ci === this.vmg[vv].ci && this.vmgSeleccionado.idtipoproyecto === this.vmg[vv].idtipoproyecto){
                            this.error_ci=true;
                            this.mensaje_error_ci='Ya existe para el proyecto seleccionado';
                        }
                    }
                }
            }
                
        }
        if (!this.error_nombre && !this.error_ci){
            if (this.vmgSeleccionado.vmgid==0){
                this._vmgService.addVmgData(this.token, this.gestion_actual, this.vmgSeleccionado).subscribe(
                    response =>{
                        if(response.codigo==200){
                            this._messageService.add({severity:'success', summary: 'Exito', detail: response.mensaje});
                            this.cargarVMG();
                        }else{
                            this._messageService.add({severity:'error', summary: 'Error', detail: response.mensaje});
                        }
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
                    }
                );
            }else{
                this.editar=true;
                this.editarDatos(this.vmgSeleccionado);
            }
            this.displayModal=false;
        }
    }
    
    editarDatos(vmg: VmgModel){
        this._vmgService.saveVmgData(this.token, vmg.vmgid, this.gestion_actual, vmg).subscribe(
            response =>{
                //console.log(response);
                if(response.codigo==200){
                    this._messageService.add({severity:'success', summary: 'Exito', detail: response.mensaje});
                    var indiceVmg = this.vmg.findIndex(x => x.vmgid === vmg.vmgid);
                    this.vmg[indiceVmg].agricola=response.agricola;
                    this.vmg[indiceVmg].pecuario=response.pecuario;
                    delete this.vmgCloned[vmg.vmgid];
                    delete this.agricolaCloned[vmg.vmgid];
                    delete this.pecuarioCloned[vmg.vmgid];
                }else{
                    this._messageService.add({severity:'error', summary: 'Error', detail: response.mensaje});
                }
                    
            },
            error=>{
                let mensaje_error=<any>error;
                this._messageService.add({severity:'error', summary: 'Error', detail: mensaje_error.error.mensaje});
            }
        );
    }
    
    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.vmgXLSX);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "VMG");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import("file-saver").then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

}
