import { Component, OnInit } from '@angular/core';
import {UsuariosService} from '../services/usuarios.service';
import {PimaService} from '../services/pima.service';
import {ResiproafService} from '../services/resiproaf.service';
import {PpiPromafiService} from '../services/ppi-promafi.service';
import { ToastrService } from 'ngx-toastr';
import {InversionesModel} from "../models/inversiones.model";

@Component({
    selector: 'app-inversiones',
    templateUrl: './inversiones.component.html',
    styleUrls: ['./inversiones.component.css'],
    providers:[UsuariosService,PimaService,ResiproafService,PpiPromafiService]
})
export class InversionesComponent implements OnInit {
    public token: string;
    public gestion: string;
    public gestion_local: string;
    
    public inversiones: Array<InversionesModel>;
    public inversiones_xlsx: Array<any>;

    constructor(
        private _usuariosService: UsuariosService,
        private _pimaService: PimaService,
        private _resiproafService: ResiproafService,
        private _ppipromafiService: PpiPromafiService,
        private _toastr: ToastrService
        ) {
            this.token = this._usuariosService.getToken();
            this.gestion = this._usuariosService.getGestion();
            this.gestion_local=this.gestion;
            if(this.gestion == ''){
                this.gestion_local = String(new Date().getFullYear());
            }
    }

    ngOnInit(): void {
        this.inversiones=[];
        this.inversiones_xlsx=[];
        var inversiones_aux=[];
        
        this._pimaService.getPimaData(this.token, this.gestion).subscribe(
            response =>{
                console.log("Pima:");
                console.log(response);
                //this.rafi=response;
                response.forEach(function(pima) {
                    inversiones_aux.push({
                        cic_nro: pima.productorci,
                        nombre_apellido: pima.productornombre+' '+pima.productorapellido,
                        fecha_nac: pima.productorfechanac,
                        edad: pima.edad,
                        sexo: pima.productorsexo,
                        tipo: pima.tipo,
                        cod_dpto: pima.dptoid,
                        departamento: pima.dptonombre,
                        id_distrito: pima.dtoid,
                        distrito: pima.dtonombre
                    });
                });
                this._resiproafService.getResiproafData(this.token, this.gestion).subscribe(
                    response_resiproaf =>{
                        console.log("resiproaf:");
                        console.log(response_resiproaf);
                        response_resiproaf.forEach(function(resiproaf) {
                            inversiones_aux.push({
                                cic_nro: resiproaf.productorci,
                                nombre_apellido: resiproaf.productornombre+' '+resiproaf.productorapellido,
                                fecha_nac: resiproaf.productorfechanac,
                                edad: resiproaf.edad,
                                sexo: resiproaf.productorsexo,
                                tipo: resiproaf.tipo,
                                cod_dpto: resiproaf.dptoid,
                                departamento: resiproaf.dptonombre,
                                id_distrito: resiproaf.dtoid,
                                distrito: resiproaf.dtonombre
                            });
                        });
                        this._ppipromafiService.getPpiPromafiData(this.token).subscribe(
                            response_ppipromafi =>{
                                console.log("ppipromafi:");
                                console.log(response_ppipromafi);
                                let gestion_actual = this.gestion_local;
                                response_ppipromafi.forEach(function(ppipromafi) {
                                    if(ppipromafi.gestion.includes(gestion_actual)){
                                        inversiones_aux.push({
                                            cic_nro: ppipromafi.ci,
                                            nombre_apellido: ppipromafi.nombre+' '+ppipromafi.nombre,
                                            fecha_nac: ppipromafi.fechanac,
                                            edad: ppipromafi.edad,
                                            sexo: ppipromafi.sexo,
                                            tipo: ppipromafi.proyecto,
                                            cod_dpto: ppipromafi.dptoid,
                                            departamento: ppipromafi.dptonombre,
                                            id_distrito: ppipromafi.dtoid,
                                            distrito: ppipromafi.dtonombre
                                        });
                                    }
                                        
                                });
                                this.inversiones = inversiones_aux;
                                for (let ii = 0; ii < this.inversiones.length; ii++){
                                    this.inversiones_xlsx.push({
                                        'CI': this.inversiones[ii].cic_nro,
                                        'Nombre y Apellido': this.inversiones[ii].nombre_apellido,
                                        'Sexo': this.inversiones[ii].sexo,
                                        'Tipo de Beneficiarios': this.inversiones[ii].tipo,
                                        'Departamento': this.inversiones[ii].departamento,
                                        'Distrito': this.inversiones[ii].distrito,
                                        'Edad': this.inversiones[ii].edad
                                    })
                                }
                                console.log(this.inversiones);
                            },
                            error=>{
                                let mensaje_error=<any>error;
                                this._toastr.error(mensaje_error.error.mensaje, 'Error');
                            }
                        );
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._toastr.error(mensaje_error.error.mensaje, 'Error');
                    }
                );
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
    }
    
    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.inversiones_xlsx);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Inversiones");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import("file-saver").then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

}
