import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import {appRoutingProviders, routing } from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import {ChartModule} from 'primeng/chart';
/*
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {ContextMenuModule} from 'primeng/contextmenu';
*/

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
//import {CalendarModule} from 'primeng/calendar';
//import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
//import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {CalendarModule} from 'primeng/calendar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {TabViewModule} from 'primeng/tabview';
import {CheckboxModule} from 'primeng/checkbox';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
//import { MessagesModule } from 'primeng/messages';
//import {ConfirmationService} from 'primeng/api';



import { LoginComponent } from './login/login.component';
import { InicioComponent } from './inicio/inicio.component';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { MenuSuperiorComponent } from './menu-superior/menu-superior.component';
import { AsistenciaTecnicaComponent } from './asistencia-tecnica/asistencia-tecnica.component';
import { InversionesComponent } from './inversiones/inversiones.component';
import { VmgComponent } from './vmg/vmg.component';
import { PpiPromafiComponent } from './ppi-promafi/ppi-promafi.component';
import { PlanProduccionComponent } from './plan-produccion/plan-produccion.component';
import { ComercializacionComponent } from './comercializacion/comercializacion.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        InicioComponent,
        MenuLateralComponent,
        MenuSuperiorComponent,
        AsistenciaTecnicaComponent,
        InversionesComponent,
        VmgComponent,
        PpiPromafiComponent,
        PlanProduccionComponent,
        ComercializacionComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        appRoutingProviders,
        routing,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(),
        /*
        TableModule,
        DropdownModule,
        ButtonModule,
        ContextMenuModule,
        */
        LoadingBarHttpClientModule,
        LoadingBarModule,
        TableModule,
        //CalendarModule,
        //SliderModule,
        DialogModule,
        MultiSelectModule,
        ContextMenuModule,
        DropdownModule,
        ButtonModule,
        ToastModule,
        InputTextModule,
        CalendarModule,
        RadioButtonModule,
        ConfirmDialogModule,
        TabViewModule,
        CheckboxModule,
        MessagesModule,
        MessageModule,
        //ConfirmationService,
        //ProgressBarModule,
        ChartModule
    ],
    providers: [
        appRoutingProviders,
        CookieService,
        {provide : LocationStrategy , useClass: HashLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }