import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {LoginComponent} from './login/login.component';
import {InicioComponent} from './inicio/inicio.component';
import {AsistenciaTecnicaComponent} from './asistencia-tecnica/asistencia-tecnica.component';
import {InversionesComponent} from './inversiones/inversiones.component';
import {PlanProduccionComponent} from './plan-produccion/plan-produccion.component';

import {VmgComponent} from './vmg/vmg.component';
import {PpiPromafiComponent} from './ppi-promafi/ppi-promafi.component';

const routes: Routes = [
    {path: '', component: LoginComponent},
    {path: 'login', component: LoginComponent},
    {path: 'inicio', component: InicioComponent},
    {path: 'asistencia-tecnica', component: AsistenciaTecnicaComponent},
    {path: 'inversiones', component: InversionesComponent},
    {path: 'plan-produccion', component: PlanProduccionComponent},
    {path: 'vmg', component: VmgComponent},
    {path: 'ppi-promafi', component: PpiPromafiComponent}
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });