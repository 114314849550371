import { Component, OnInit } from '@angular/core';
import {UsuariosService} from '../services/usuarios.service';
declare var $: any;

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css'],
  providers:[UsuariosService]
})
export class MenuLateralComponent implements OnInit {
    public gestion: string;
    public gestion_mostrar: string;
    constructor(
        private _usuariosService: UsuariosService
    ) {
        this.gestion = this._usuariosService.getGestion();
    }

    ngOnInit(): void {
        //console.log(this.gestion);
        this.gestion_mostrar = this.gestion;
        if(this.gestion==''){
            this.gestion_mostrar = String(new Date().getFullYear());
        }
    }
  
    sidebarToggle(){
          $("body").toggleClass("sidebar-toggled");
          $(".sidebar").toggleClass("toggled");
          if ($(".sidebar").hasClass("toggled")) {
              $('.sidebar .collapse').collapse('hide');
          };
      }

}
