import { Component, OnInit } from '@angular/core';
import {UsuariosService} from '../services/usuarios.service';
import {RafiService} from '../services/rafi.service';
import {RafaService} from '../services/rafa.service';
import {VmgService} from '../services/vmg.service';

import {PimaService} from '../services/pima.service';
import {ResiproafService} from '../services/resiproaf.service';
import {PpiPromafiService} from '../services/ppi-promafi.service';
/*
import {RafiService} from '../services/rafi.service';
import {VisitaService} from '../services/visita.service';
import {VisitaRafiService} from '../services/visita-rafi.service';
import {PlanService} from '../services/plan.service';
import {PlanIndigenaService} from '../services/plan-indigena.service';
import {PlanSustantivoService} from '../services/plan-sustantivo.service';
import {RafaService} from '../services/rafa.service';
import {LbaseService} from '../services/lbase.service';

import {RenebaeService} from '../services/renebae.service';

*/
import { ToastrService } from 'ngx-toastr';
import {AsistenaciaTecnicaModel} from "../models/asistencia-tecnica.model";
import {InversionesModel} from "../models/inversiones.model";

@Component({
    selector: 'app-inicio',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.css'],
    providers:[UsuariosService,RafiService,RafaService,VmgService,PimaService,ResiproafService,PpiPromafiService]
})
export class InicioComponent implements OnInit {
    public token: string;
    public gestion: string;
    public gestion_local: string;
    
    public asistencia_tecnica: Array<AsistenaciaTecnicaModel>;
    public asistencia_tecnica_distinct: Array<AsistenaciaTecnicaModel>;
    public asistencia_tecnica_by_dpto_array: Array<any>;
    public asistencia_tecnica_agricola: Array<any>;
    public departamento_seleccionado: string;
    
    public at_total_M: number=0;
    public at_total_F: number=0;
        
    public at1_donutData: any;
    public at1_donutOptions: any;
    
    public at1Data: any;
    public at1Options: any;
    
    public at1Data_dto: any;
    public at1Options_dto: any;
    
    public at2Data: any;
    public at2Options: any;
    
    public inversiones: Array<InversionesModel>;
    public inversiones_distinct: Array<InversionesModel>;
    public inversiones_by_dpto_array: Array<any>;
    public departamento_seleccionado_inv: string;
    public inv_total_M: number=0;
    public inv_total_F: number=0;
    
    public inv1_donutData: any;
    public inv1_donutOptions: any;
    public inv1Data: any;
    public inv1Options: any;
    
    public inv1Data_dto: any;
    public inv1Options_dto: any;

    
    public inv2Data: any;
    public inv2Options: any;
    
    
    public departamento_seleccionado_asistencia_tecnica_agricola: string=null;
    public departamentos_agricola: Array<any>=[];
    public distrito_seleccionado_asistencia_tecnica_agricola: string=null;
    public distritos_agricola: Array<any>=[];
    public asistencia_tecnica_agricola_data: any;
    public asistencia_tecnica_agricola_options: any;
    
    
    
    public colores: Array<string>;

    constructor(
        private _usuariosService: UsuariosService,
        private _rafiService: RafiService,
        private _rafaService: RafaService,
        private _vmgService: VmgService,
        private _pimaService: PimaService,
        private _resiproafService: ResiproafService,
        private _ppipromafiService: PpiPromafiService,
        /*
        private _visitaService: VisitaService,
        private _visitaRafiService: VisitaRafiService,
        private _planService: PlanService,
        private _planIndigenaService: PlanIndigenaService,
        private _planSustantivoService: PlanSustantivoService,
        private _lbaseService: LbaseService,
        
        private _renebaeService: RenebaeService,
        
        */
        private _toastr: ToastrService
        ) {
            this.token = this._usuariosService.getToken();
            this.gestion = this._usuariosService.getGestion();
            this.gestion_local=this.gestion;
            if(this.gestion == ''){
                this.gestion_local = String(new Date().getFullYear());
            }
            this.departamento_seleccionado=null;
            this.colores=[
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                '#32a852',
                '#AB47BC',
                '#42A5F5',
                '#7E57C2',
                '#66BB6A',
                '#FFCA28',
                '#26A69A'
            ];
            
            this.asistencia_tecnica_agricola_options = {
                scaleShowValues: true,
                mantainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057',
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                    /*,
                    yAxes: [{
                        ticks: {
                          //autoSkip: false,
                          autoSkipPadding: 15
                          
                        }
                    }]
                    */
                }
            };
    }

    ngOnInit(): void {
        this.asistencia_tecnica=[];
        this.asistencia_tecnica_distinct=[];
        var asistencia_tecnica_aux=[];
        var asistencia_tecnica_aux_distinct=[];
        this.asistencia_tecnica_agricola=[];
        var asistencia_tecnica_agricola_aux=[];
        this._rafiService.getRafiData(this.token,this.gestion).subscribe(
            response =>{
                console.log("Rafi:");
                console.log(response);
                //this.rafi=response;
                response.forEach(function(rafi) {
                    asistencia_tecnica_aux.push({
                        cic_nro: rafi.cic_nro,
                        nombre_apellido: rafi.nombre_apellido,
                        fecha_nac: rafi.fecha_nac,
                        edad: rafi.edad,
                        sexo: rafi.sexo,
                        tipo: rafi.tipo,
                        cod_dpto: rafi.cod_dpto,
                        departamento: rafi.departamento,
                        id_distrito: rafi.id_distrito,
                        distrito: rafi.distrito
                    });
                    /*
                    for(let ag=0;ag<rafi.agricola.length;ag++){
                        var indiceAgricola = asistencia_tecnica_agricola_aux.findIndex(x => (x.cultivos === rafi.agricola[ag].cultivos && x.departamento === rafi.departamento && x.distrito === rafi.distrito && x.tipo === 'RAFI'));
                        if(indiceAgricola>=0){
                            asistencia_tecnica_agricola_aux[indiceAgricola].total_sup=asistencia_tecnica_agricola_aux[indiceAgricola].total_sup+rafi.agricola[ag].total_sup;
                        }else{
                            asistencia_tecnica_agricola_aux.push({
                                cultivos: rafi.agricola[ag].cultivos,
                                departamento: rafi.departamento,
                                distrito: rafi.distrito,
                                tipo: 'RAFI',
                                total_sup: rafi.agricola[ag].total_sup
                            });
                        }
                    }
                    */
                    var indiceUsuario = asistencia_tecnica_aux_distinct.findIndex(x => x.cic_nro === rafi.cic_nro);
                    if(indiceUsuario===-1){
                        asistencia_tecnica_aux_distinct.push({
                            cic_nro: rafi.cic_nro,
                            nombre_apellido: rafi.nombre_apellido,
                            fecha_nac: rafi.fecha_nac,
                            edad: rafi.edad,
                            sexo: rafi.sexo,
                            tipo: rafi.tipo,
                            cod_dpto: rafi.cod_dpto,
                            departamento: rafi.departamento,
                            id_distrito: rafi.id_distrito,
                            distrito: rafi.distrito
                        });
                    }
                    
                });
                this._rafaService.getRafaData(this.token, this.gestion).subscribe(
                    response_rafa =>{
                        console.log("Rafa:");
                        console.log(response_rafa);
                        response_rafa.forEach(function(rafa) {
                            asistencia_tecnica_aux.push({
                                cic_nro: rafa.cic_nro,
                                nombre_apellido: rafa.nombre_apellido,
                                fecha_nac: rafa.fecha_nac,
                                edad: rafa.edad,
                                sexo: rafa.sexo,
                                tipo: rafa.tipo,
                                cod_dpto: rafa.cod_dpto,
                                departamento: rafa.departamento,
                                id_distrito: rafa.id_distrito,
                                distrito: rafa.distrito
                            });
                            /*
                            for(let ag=0;ag<rafa.agricola.length;ag++){
                                var indiceAgricola = asistencia_tecnica_agricola_aux.findIndex(x => (x.cultivos === rafa.agricola[ag].cultivos && x.departamento === rafa.departamento && x.distrito === rafa.distrito && x.tipo === 'RAFI'));
                                if(indiceAgricola>=0){
                                    asistencia_tecnica_agricola_aux[indiceAgricola].total_sup=asistencia_tecnica_agricola_aux[indiceAgricola].total_sup+rafa.agricola[ag].total_sup;
                                }else{
                                    asistencia_tecnica_agricola_aux.push({
                                        cultivos: rafa.agricola[ag].cultivos,
                                        departamento: rafa.departamento,
                                        distrito: rafa.distrito,
                                        tipo: 'RAFA',
                                        total_sup: rafa.agricola[ag].total_sup
                                    });
                                }
                            }
                            */
                            var indiceUsuario = asistencia_tecnica_aux_distinct.findIndex(x => x.cic_nro === rafa.cic_nro);
                            if(indiceUsuario===-1){
                                asistencia_tecnica_aux_distinct.push({
                                    cic_nro: rafa.cic_nro,
                                    nombre_apellido: rafa.nombre_apellido,
                                    fecha_nac: rafa.fecha_nac,
                                    edad: rafa.edad,
                                    sexo: rafa.sexo,
                                    tipo: rafa.tipo,
                                    cod_dpto: rafa.cod_dpto,
                                    departamento: rafa.departamento,
                                    id_distrito: rafa.id_distrito,
                                    distrito: rafa.distrito
                                });
                            }
                        });
                        this._vmgService.getVmgData(this.token, this.gestion_local).subscribe(
                            response_vmg =>{
                                console.log("VMG:");
                                console.log(response_vmg);
                                let gestion_actual = this.gestion_local;
                                
                                response_vmg.forEach(function(vmg) {
                                    if(vmg.gestion.includes(gestion_actual)){
                                        asistencia_tecnica_aux.push({
                                            cic_nro: vmg.ci,
                                            nombre_apellido: vmg.nombre+' '+vmg.nombre,
                                            fecha_nac: vmg.fechanac,
                                            edad: vmg.edad,
                                            sexo: vmg.sexo,
                                            tipo: vmg.tipoproyecto,
                                            cod_dpto: vmg.dptoid,
                                            departamento: vmg.dptonombre,
                                            id_distrito: vmg.dptodtoid,
                                            distrito: vmg.dtonombre
                                        });
                                        /*
                                        for(let ag=0;ag<vmg.agricola.length;ag++){
                                            var indiceAgricola = asistencia_tecnica_agricola_aux.findIndex(x => (x.cultivos === vmg.agricola[ag].cultivo && x.departamento === vmg.dptonombre && x.distrito === vmg.dtonombre && x.tipo === vmg.tipoproyecto));
                                            if(indiceAgricola>=0){
                                                asistencia_tecnica_agricola_aux[indiceAgricola].total_sup=asistencia_tecnica_agricola_aux[indiceAgricola].total_sup+vmg.agricola[ag].total_sup;
                                            }else{
                                                asistencia_tecnica_agricola_aux.push({
                                                    cultivos: vmg.agricola[ag].cultivo,
                                                    departamento: vmg.dptonombre,
                                                    distrito: vmg.dtonombre,
                                                    tipo: vmg.tipoproyecto,
                                                    total_sup: vmg.agricola[ag].total_sup
                                                });
                                            }
                                        }
                                        */
                                        var indiceUsuario = asistencia_tecnica_aux_distinct.findIndex(x => x.cic_nro === vmg.ci);
                                        if(indiceUsuario===-1){
                                            asistencia_tecnica_aux_distinct.push({
                                                cic_nro: vmg.ci,
                                                nombre_apellido: vmg.nombre+' '+vmg.nombre,
                                                fecha_nac: vmg.fechanac,
                                                edad: vmg.edad,
                                                sexo: vmg.sexo,
                                                tipo: vmg.tipoproyecto,
                                                cod_dpto: vmg.dptoid,
                                                departamento: vmg.dptonombre,
                                                id_distrito: vmg.dptodtoid,
                                                distrito: vmg.dtonombre
                                            });
                                        }
                                    }
                                        
                                });
                                    
                                
                                this.asistencia_tecnica=asistencia_tecnica_aux;
                                this.asistencia_tecnica_distinct=asistencia_tecnica_aux_distinct;
                                this.asistencia_tecnica_agricola = asistencia_tecnica_agricola_aux;
                                console.log(this.asistencia_tecnica_agricola);
                                /*
                                for (let at = 0; at < this.asistencia_tecnica.length; at++){
                                    var indiceUsuario = this.asistencia_tecnica_distinct.findIndex(x => x.cic_nro === this.asistencia_tecnica[at].cic_nro);
                                    if(indiceUsuario===-1){
                                        this.asistencia_tecnica_distinct.push({
                                            cic_nro: this.asistencia_tecnica[at].cic_nro,
                                            nombre_apellido: this.asistencia_tecnica[at].nombre_apellido,
                                            fecha_nac: this.asistencia_tecnica[at].fecha_nac,
                                            edad: this.asistencia_tecnica[at].edad,
                                            sexo: this.asistencia_tecnica[at].sexo,
                                            tipo: this.asistencia_tecnica[at].tipo,
                                            cod_dpto: this.asistencia_tecnica[at].cod_dpto,
                                            departamento: this.asistencia_tecnica[at].departamento,
                                            id_distrito: this.asistencia_tecnica[at].id_distrito,
                                            distrito: this.asistencia_tecnica[at].distrito
                                        });
                                    }
                                }
                                */
                                
                                console.log(this.asistencia_tecnica);
                                console.log(this.asistencia_tecnica_distinct);
                                
                                
                                this.at_total_M = this.asistencia_tecnica_distinct.reduce(function (n, person) {
                                    return n + (person.sexo == 'M'? 1 : 0);
                                }, 0);
                                this.at_total_F = this.asistencia_tecnica_distinct.reduce(function (n, person) {
                                    return n + (person.sexo == 'F'? 1 : 0);
                                }, 0);
                                
                                
                                let asistencia_tecnica_aux_by_tipo = this.groupBy(this.asistencia_tecnica, x => x.tipo);
                                const asistencia_tecnica_aux_by_tipo_array = [...asistencia_tecnica_aux_by_tipo].map(function (item) {
                                    return { key: item[0], value: item[1], total: item[1].length }
                                });
                                let labels_at1_donut=[];
                                let data_at1_donut=[];
                                let colores_at1_donut=[];
                                
                                for (let dpto = 0; dpto < asistencia_tecnica_aux_by_tipo_array.length; dpto++){
                                    labels_at1_donut.push(asistencia_tecnica_aux_by_tipo_array[dpto].key);
                                    data_at1_donut.push(asistencia_tecnica_aux_by_tipo_array[dpto].total);
                                    colores_at1_donut.push(this.colores[dpto]);
                                }
                                //console.log(asistencia_tecnica_aux_by_tipo_array);
                                
                                
                                
                                let asistencia_tecnica_aux_by_dpto = this.groupBy(this.asistencia_tecnica_distinct, x => x.departamento);
                                const asistencia_tecnica_aux_by_dpto_array = [...asistencia_tecnica_aux_by_dpto].map(function (item) {
                                    return { key: item[0], value: item[1], hombres:0, mujeres: 0, sininfo: 0 }
                                });
                                let existe_sininfo=false;
                                let labels=[];
                                let dataT=[];
                                let dataH=[];
                                let dataF=[];
                                for (let dpto = 0; dpto < asistencia_tecnica_aux_by_dpto_array.length; dpto++){
                                    
                                    labels.push(asistencia_tecnica_aux_by_dpto_array[dpto].key);
                                    
                                    for (let dd = 0; dd < asistencia_tecnica_aux_by_dpto_array[dpto].value.length; dd++){
                                        switch(asistencia_tecnica_aux_by_dpto_array[dpto].value[dd].sexo){
                                            case 'M':
                                                asistencia_tecnica_aux_by_dpto_array[dpto].hombres++;
                                                break;
                                            case 'F':
                                                asistencia_tecnica_aux_by_dpto_array[dpto].mujeres++;
                                                break;
                                            default:
                                                asistencia_tecnica_aux_by_dpto_array[dpto].sininfo++;
                                                existe_sininfo=true;
                                                break;
                                        }
                                    }
                                    
                                    dataH.push(asistencia_tecnica_aux_by_dpto_array[dpto].hombres);
                                    dataF.push(asistencia_tecnica_aux_by_dpto_array[dpto].mujeres);
                                    dataT.push(asistencia_tecnica_aux_by_dpto_array[dpto].hombres+asistencia_tecnica_aux_by_dpto_array[dpto].mujeres);
                                    
                                    
                                    
                                }
                                //console.log(asistencia_tecnica_aux_by_dpto_array);
                                this.asistencia_tecnica_by_dpto_array=asistencia_tecnica_aux_by_dpto_array;
                                
                                
                                this.at1Data = {
                                    labels: labels,
                                    datasets: [
                                        {
                                            label: 'Hombres',
                                            backgroundColor: '#42A5F5',
                                            data: dataH
                                        },
                                        {
                                            label: 'Mujeres',
                                            backgroundColor: '#FFA726',
                                            data: dataF
                                        }
                                    ]
                                };

                                this.at1Options = {
                                    plugins: {
                                        legend: {
                                            labels: {
                                                color: '#495057'
                                            }
                                        }
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                color: '#495057'
                                            },
                                            grid: {
                                                color: '#ebedef'
                                            }
                                        },
                                        y: {
                                            ticks: {
                                                color: '#495057'
                                            },
                                            grid: {
                                                color: '#ebedef'
                                            }
                                        }
                                    }
                                };
                                
                                
                                this.at1_donutData = {
                                    labels: labels_at1_donut,
                                    datasets: [
                                        {
                                            data: data_at1_donut,
                                            backgroundColor: colores_at1_donut,
                                            hoverBackgroundColor: colores_at1_donut
                                        }
                                    ]
                                };

                                this.at1_donutOptions={
                                    plugins: {
                                        legend: {
                                            labels: {
                                                color: '#495057'
                                            }
                                        }
                                    }
                                };
                                
                                
                                
                                
                                
                                
                                
                                //console.log(this.asistencia_tecnica);
                                
                                
                                
                                
                            },
                            error=>{
                                let mensaje_error=<any>error;
                                this._toastr.error(mensaje_error.error.mensaje, 'Error');
                            }
                        );
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._toastr.error(mensaje_error.error.mensaje, 'Error');
                    }
                );
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this._rafiService.getRafiRubroData(this.token,this.gestion).subscribe(
            response_rafi =>{
                console.log("RafiRubro:");
                //console.log(response);
                //this.rafi=response;
                response_rafi.forEach(function(rafi) {
                    asistencia_tecnica_agricola_aux.push({
                        cultivos: rafi.cultivos,
                        total_sup: rafi.total_sup,
                        departamento: rafi.departamento,
                        distrito: rafi.distrito
                    });
                });
                this._rafaService.getRafaRubroData(this.token,this.gestion).subscribe(
                    response_rafa =>{
                        response_rafa.forEach(function(rafa) {
                            asistencia_tecnica_agricola_aux.push({
                                cultivos: rafa.cultivos,
                                total_sup: rafa.total_sup,
                                departamento: rafa.departamento,
                                distrito: rafa.distrito
                            });
                            /*
                            var indiceRubro = asistencia_tecnica_agricola_aux.findIndex(x => (x.cultivos === rafa.cultivos && x.departamento === rafa.departamento && x.distrito === rafa.distrito));
                            if(indiceRubro>=0){
                                asistencia_tecnica_agricola_aux[indiceRubro].total_sup=asistencia_tecnica_agricola_aux[indiceRubro].total_sup+rafa.total_sup;
                            }else{
                                asistencia_tecnica_agricola_aux.push({
                                    cultivos: rafa.cultivos,
                                    total_sup: rafa.total_sup,
                                    departamento: rafa.departamento,
                                    distrito: rafa.distrito
                                });
                            }
                            */
                                
                        });
                        
                        this._vmgService.getVmgRubroData(this.token, this.gestion_local).subscribe(
                            response_vmg =>{
                                response_vmg.forEach(function(vmg) {
                                    asistencia_tecnica_agricola_aux.push({
                                        cultivos: vmg.cultivo,
                                        total_sup: vmg.total_sup,
                                        departamento: vmg.dptonombre,
                                        distrito: vmg.dtonombre
                                    });
                                });
                                this.asistencia_tecnica_agricola = asistencia_tecnica_agricola_aux;

                                let departamentos_agricola_aux = this.groupBy(this.asistencia_tecnica_agricola, x => x.departamento);
                                this.departamentos_agricola = [...departamentos_agricola_aux].map(function (item) {
                                    return { key: item[0], value: item[1], hombres:0, mujeres: 0, sininfo: 0 }
                                });


                                console.log(this.asistencia_tecnica_agricola);
                                console.log(this.departamentos_agricola);
                                this.cargarCadroATRubro();
                            },
                            error=>{
                                let mensaje_error=<any>error;
                                this._toastr.error(mensaje_error.error.mensaje, 'Error');
                            }
                        );
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._toastr.error(mensaje_error.error.mensaje, 'Error');
                    }
                );
                
                        
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this.inversiones=[];
        this.inversiones_distinct=[];
        var inversiones_aux=[];
        var inversiones_aux_distinct=[];
        this._pimaService.getPimaData(this.token,this.gestion).subscribe(
            response_pima =>{
                console.log("Pima:");
                console.log(response_pima);
                
                response_pima.forEach(function(pima) {
                    inversiones_aux.push({
                        cic_nro: pima.productorci,
                        nombre_apellido: pima.productornombre+' '+pima.productorapellido,
                        fecha_nac: pima.productorfechanac,
                        edad: pima.edad,
                        sexo: pima.productorsexo,
                        tipo: pima.tipo,
                        cod_dpto: pima.dptoid,
                        departamento: pima.dptonombre,
                        id_distrito: pima.dtoid,
                        distrito: pima.dtonombre
                    });
                    var indiceUsuario = inversiones_aux_distinct.findIndex(x => x.cic_nro === pima.productorci);
                    if(indiceUsuario===-1){
                        inversiones_aux_distinct.push({
                            cic_nro: pima.productorci,
                            nombre_apellido: pima.productornombre+' '+pima.productorapellido,
                            fecha_nac: pima.productorfechanac,
                            edad: pima.edad,
                            sexo: pima.productorsexo,
                            tipo: pima.tipo,
                            cod_dpto: pima.dptoid,
                            departamento: pima.dptonombre,
                            id_distrito: pima.dtoid,
                            distrito: pima.dtonombre
                        });
                    }
                });
                
                this._resiproafService.getResiproafData(this.token,this.gestion).subscribe(
                    response_resiproaf=>{
                        console.log("resiproaf:");
                        console.log(response_resiproaf);
                        
                        response_resiproaf.forEach(function(resiproaf) {
                            inversiones_aux.push({
                                cic_nro: resiproaf.productorci,
                                nombre_apellido: resiproaf.productornombre + ' ' + resiproaf.productorapellido,
                                fecha_nac: resiproaf.productorfechanac,
                                edad: resiproaf.edad,
                                sexo: resiproaf.productorsexo,
                                tipo: resiproaf.tipo,
                                cod_dpto: resiproaf.dptoid,
                                departamento: resiproaf.dptonombre,
                                id_distrito: resiproaf.dtoid,
                                distrito: resiproaf.dtonombre
                            });
                            var indiceUsuario = inversiones_aux_distinct.findIndex(x => x.cic_nro === resiproaf.productorci);
                            if(indiceUsuario===-1){
                                inversiones_aux_distinct.push({
                                    cic_nro: resiproaf.productorci,
                                    nombre_apellido: resiproaf.productornombre + ' ' + resiproaf.productorapellido,
                                    fecha_nac: resiproaf.productorfechanac,
                                    edad: resiproaf.edad,
                                    sexo: resiproaf.productorsexo,
                                    tipo: resiproaf.tipo,
                                    cod_dpto: resiproaf.dptoid,
                                    departamento: resiproaf.dptonombre,
                                    id_distrito: resiproaf.dtoid,
                                    distrito: resiproaf.dtonombre
                                });
                            }
                        });
                        
                        this._ppipromafiService.getPpiPromafiData(this.token).subscribe(
                            response_ppipromafi =>{
                                console.log("ppipromafi:");
                                console.log(response_ppipromafi);
                                let gestion_actual = this.gestion_local;
                                response_ppipromafi.forEach(function(ppipromafi) {
                                    if(ppipromafi.gestion.includes(gestion_actual)){
                                        inversiones_aux.push({
                                            cic_nro: ppipromafi.ci,
                                            nombre_apellido: ppipromafi.nombre+' '+ppipromafi.apellido,
                                            fecha_nac: ppipromafi.fechanac,
                                            edad: ppipromafi.edad,
                                            sexo: ppipromafi.sexo,
                                            tipo: ppipromafi.proyecto,
                                            cod_dpto: ppipromafi.dptoid,
                                            departamento: ppipromafi.dptonombre,
                                            id_distrito: ppipromafi.dptodtoid,
                                            distrito: ppipromafi.dtonombre
                                        });
                                        var indiceUsuario = inversiones_aux_distinct.findIndex(x => x.cic_nro === ppipromafi.ci);
                                        if(indiceUsuario===-1){
                                            inversiones_aux_distinct.push({
                                                cic_nro: ppipromafi.ci,
                                                nombre_apellido: ppipromafi.nombre+' '+ppipromafi.apellido,
                                                fecha_nac: ppipromafi.fechanac,
                                                edad: ppipromafi.edad,
                                                sexo: ppipromafi.sexo,
                                                tipo: ppipromafi.proyecto,
                                                cod_dpto: ppipromafi.dptoid,
                                                departamento: ppipromafi.dptonombre,
                                                id_distrito: ppipromafi.dptodtoid,
                                                distrito: ppipromafi.dtonombre
                                            });
                                        }
                                    }
                                        
                                });
                                
                                
                                this.inversiones = inversiones_aux;
                                this.inversiones_distinct = inversiones_aux_distinct;
                                //console.log(this.inversiones);
                                
                                this.inv_total_M = this.inversiones_distinct.reduce(function (n, person) {
                                    return n + (person.sexo == 'M'? 1 : 0);
                                }, 0);
                                this.inv_total_F = this.inversiones_distinct.reduce(function (n, person) {
                                    return n + (person.sexo == 'F'? 1 : 0);
                                }, 0);
                                
                                
                                let inversiones_aux_by_tipo = this.groupBy(this.inversiones, x => x.tipo);
                                const inversiones_aux_by_tipo_array = [...inversiones_aux_by_tipo].map(function (item) {
                                    return { key: item[0], value: item[1], total: item[1].length }
                                });
                                let labels_inv1_donut=[];
                                let data_inv1_donut=[];
                                let colores_inv1_donut=[];
                                
                                for (let dpto = 0; dpto < inversiones_aux_by_tipo_array.length; dpto++){
                                    labels_inv1_donut.push(inversiones_aux_by_tipo_array[dpto].key);
                                    data_inv1_donut.push(inversiones_aux_by_tipo_array[dpto].total);
                                    colores_inv1_donut.push(this.colores[dpto]);
                                }
                                
                                this.inv1_donutData = {
                                    labels: labels_inv1_donut,
                                    datasets: [
                                        {
                                            data: data_inv1_donut,
                                            backgroundColor: colores_inv1_donut,
                                            hoverBackgroundColor: colores_inv1_donut
                                        }
                                    ]
                                };

                                this.inv1_donutOptions={
                                    plugins: {
                                        legend: {
                                            labels: {
                                                color: '#495057'
                                            }
                                        }
                                    }
                                };
                                
                                
                                let inversiones_aux_by_dpto = this.groupBy(this.inversiones_distinct, x => x.departamento);
                                const inversiones_aux_by_dpto_array = [...inversiones_aux_by_dpto].map(function (item) {
                                    return { key: item[0], value: item[1], hombres:0, mujeres: 0, sininfo: 0 }
                                });
                                let existe_sininfo_inv=false;
                                let labels_inv=[];
                                let dataT_inv=[];
                                let dataH_inv=[];
                                let dataF_inv=[];
                                for (let dpto = 0; dpto < inversiones_aux_by_dpto_array.length; dpto++){
                                    
                                    labels_inv.push(inversiones_aux_by_dpto_array[dpto].key);
                                    
                                    for (let dd = 0; dd < inversiones_aux_by_dpto_array[dpto].value.length; dd++){
                                        switch(inversiones_aux_by_dpto_array[dpto].value[dd].sexo){
                                            case 'M':
                                                inversiones_aux_by_dpto_array[dpto].hombres++;
                                                break;
                                            case 'F':
                                                inversiones_aux_by_dpto_array[dpto].mujeres++;
                                                break;
                                            default:
                                                inversiones_aux_by_dpto_array[dpto].sininfo++;
                                                existe_sininfo_inv=true;
                                                break;
                                        }
                                    }
                                    
                                    dataH_inv.push(inversiones_aux_by_dpto_array[dpto].hombres);
                                    dataF_inv.push(inversiones_aux_by_dpto_array[dpto].mujeres);
                                    dataT_inv.push(inversiones_aux_by_dpto_array[dpto].hombres+inversiones_aux_by_dpto_array[dpto].mujeres);
                                    
                                    
                                    
                                }
                                this.inversiones_by_dpto_array=inversiones_aux_by_dpto_array;
                                
                                
                                this.inv1Data = {
                                    labels: labels_inv,
                                    datasets: [
                                        {
                                            label: 'Hombres',
                                            backgroundColor: '#42A5F5',
                                            data: dataH_inv
                                        },
                                        {
                                            label: 'Mujeres',
                                            backgroundColor: '#FFA726',
                                            data: dataF_inv
                                        }
                                    ]
                                };

                                this.inv1Options = {
                                    plugins: {
                                        legend: {
                                            labels: {
                                                color: '#495057'
                                            }
                                        }
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                color: '#495057'
                                            },
                                            grid: {
                                                color: '#ebedef'
                                            }
                                        },
                                        y: {
                                            ticks: {
                                                color: '#495057'
                                            },
                                            grid: {
                                                color: '#ebedef'
                                            }
                                        }
                                    }
                                };

                            },
                            error=>{
                                let mensaje_error=<any>error;
                                this._toastr.error(mensaje_error.error.mensaje, 'Error');
                            }
                        );
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._toastr.error(mensaje_error.error.mensaje, 'Error');
                    }
                );
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        
        /*
        this._rafiService.getRafiData(this.token).subscribe(
            response =>{
                console.log("Rafi:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this._visitaService.getVisitaData(this.token).subscribe(
            response =>{
                console.log("Visita:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        this._visitaRafiService.getVisitaRafiData(this.token).subscribe(
            response =>{
                console.log("VisitaRafi:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        this._planService.getPlanData(this.token).subscribe(
            response =>{
                console.log("Plan:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        this._planIndigenaService.getPlanIndigenaData(this.token).subscribe(
            response =>{
                console.log("PlanIndigena:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        this._planSustantivoService.getPlanSustantivoData(this.token).subscribe(
            response =>{
                console.log("PlanSustantivo:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        this._rafaService.getRafaData(this.token).subscribe(
            response =>{
                console.log("Rafa:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        this._lbaseService.getLbaseData(this.token).subscribe(
            response =>{
                console.log("Lbase:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this._pimaService.getPimaData(this.token).subscribe(
            response =>{
                console.log("Pima:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this._renebaeService.getRenebaeData(this.token).subscribe(
            response =>{
                console.log("Renebae:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
        this._resiproafService.getResiproafData(this.token).subscribe(
            response =>{
                console.log("resiproaf:");
                console.log(response);
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        */
        
        
        
        

    }
    
    cargarCuadroAT(){
        var indiceDpto = this.asistencia_tecnica_by_dpto_array.findIndex(x => x.key === this.departamento_seleccionado);
        
        //console.log(this.asistencia_tecnica_by_dpto_array[indiceDpto].value);
        let asistencia_tecnica_by_dto=[];
        for(let dd=0;dd<this.asistencia_tecnica_by_dpto_array[indiceDpto].value.length;dd++){
            asistencia_tecnica_by_dto.push({
                'distrito': this.asistencia_tecnica_by_dpto_array[indiceDpto].value[dd].distrito,
                'sexo': this.asistencia_tecnica_by_dpto_array[indiceDpto].value[dd].sexo,
            });
        }

        
        let asistencia_tecnica_aux_by_dto = this.groupBy(asistencia_tecnica_by_dto, x => x.distrito);
        const asistencia_tecnica_aux_by_dto_array = [...asistencia_tecnica_aux_by_dto].map(function (item) {
            return { key: item[0], value: item[1], hombres:0, mujeres: 0, sininfo: 0 }
        });
        
        let existe_sininfo_dto=false;
        let labels_dto=[];
        let dataT_dto=[];
        let dataH_dto=[];
        let dataF_dto=[];
        
        for (let dpto = 0; dpto < asistencia_tecnica_aux_by_dto_array.length; dpto++){
                                    
            labels_dto.push(asistencia_tecnica_aux_by_dto_array[dpto].key);

            for (let dd = 0; dd < asistencia_tecnica_aux_by_dto_array[dpto].value.length; dd++){
                switch(asistencia_tecnica_aux_by_dto_array[dpto].value[dd].sexo){
                    case 'M':
                        asistencia_tecnica_aux_by_dto_array[dpto].hombres++;
                        break;
                    case 'F':
                        asistencia_tecnica_aux_by_dto_array[dpto].mujeres++;
                        break;
                    default:
                        asistencia_tecnica_aux_by_dto_array[dpto].sininfo++;
                        existe_sininfo_dto=true;
                        break;
                }
            }

            dataH_dto.push(asistencia_tecnica_aux_by_dto_array[dpto].hombres);
            dataF_dto.push(asistencia_tecnica_aux_by_dto_array[dpto].mujeres);
            dataT_dto.push(asistencia_tecnica_aux_by_dto_array[dpto].hombres+asistencia_tecnica_aux_by_dto_array[dpto].mujeres);



        }
        
        //console.log(asistencia_tecnica_aux_by_dto_array);
        
        this.at1Data_dto = {
            labels: labels_dto,
            datasets: [
                {
                    label: 'Hombres',
                    backgroundColor: '#66BB6A',
                    data: dataH_dto
                },
                {
                    label: 'Mujeres',
                    backgroundColor: '#78909C',
                    data: dataF_dto
                }
            ]
        };

        this.at1Options_dto = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
        
        
        
    }
    
    cargarCuadroINV(){
        var indiceDpto = this.inversiones_by_dpto_array.findIndex(x => x.key === this.departamento_seleccionado_inv);
        
        //console.log(this.inversiones_by_dpto_array[indiceDpto].value);
        let inversiones_by_dto=[];
        for(let dd=0;dd<this.inversiones_by_dpto_array[indiceDpto].value.length;dd++){
            inversiones_by_dto.push({
                'distrito': this.inversiones_by_dpto_array[indiceDpto].value[dd].distrito,
                'sexo': this.inversiones_by_dpto_array[indiceDpto].value[dd].sexo,
            });
        }

        
        let inversiones_aux_by_dto = this.groupBy(inversiones_by_dto, x => x.distrito);
        const inversiones_aux_by_dto_array = [...inversiones_aux_by_dto].map(function (item) {
            return { key: item[0], value: item[1], hombres:0, mujeres: 0, sininfo: 0 }
        });
        
        let existe_sininfo_dto_inv=false;
        let labels_dto_inv=[];
        let dataT_dto_inv=[];
        let dataH_dto_inv=[];
        let dataF_dto_inv=[];
        
        for (let dpto = 0; dpto < inversiones_aux_by_dto_array.length; dpto++){
                                    
            labels_dto_inv.push(inversiones_aux_by_dto_array[dpto].key);

            for (let dd = 0; dd < inversiones_aux_by_dto_array[dpto].value.length; dd++){
                switch(inversiones_aux_by_dto_array[dpto].value[dd].sexo){
                    case 'M':
                        inversiones_aux_by_dto_array[dpto].hombres++;
                        break;
                    case 'F':
                        inversiones_aux_by_dto_array[dpto].mujeres++;
                        break;
                    default:
                        inversiones_aux_by_dto_array[dpto].sininfo++;
                        existe_sininfo_dto_inv=true;
                        break;
                }
            }

            dataH_dto_inv.push(inversiones_aux_by_dto_array[dpto].hombres);
            dataF_dto_inv.push(inversiones_aux_by_dto_array[dpto].mujeres);
            dataT_dto_inv.push(inversiones_aux_by_dto_array[dpto].hombres+inversiones_aux_by_dto_array[dpto].mujeres);



        }
        
        //console.log(inversiones_aux_by_dto_array);
        
        this.inv1Data_dto = {
            labels: labels_dto_inv,
            datasets: [
                {
                    label: 'Hombres',
                    backgroundColor: '#42A5F5',
                    data: dataH_dto_inv
                },
                {
                    label: 'Mujeres',
                    backgroundColor: '#FFA726',
                    data: dataF_dto_inv
                }
            ]
        };

        this.inv1Options_dto = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
        
        
        
    }
    
    cambioDepartamentoATRubro(){
        this.distritos_agricola=[];
        let asistencia_tecnica_agricola_filtro=[];
        this.distrito_seleccionado_asistencia_tecnica_agricola=null;
        
            let departamento_seleccionado_asistencia_tecnica_agricola_aux=this.departamento_seleccionado_asistencia_tecnica_agricola;
            asistencia_tecnica_agricola_filtro = this.asistencia_tecnica_agricola.filter(function(item){
                return item.departamento==departamento_seleccionado_asistencia_tecnica_agricola_aux;
            });

            let distritos_agricola_aux = this.groupBy(asistencia_tecnica_agricola_filtro, x => x.distrito);
            this.distritos_agricola = [...distritos_agricola_aux].map(function (item) {
                return { key: item[0], value: item[1], hombres:0, mujeres: 0, sininfo: 0 }
            });
        
        this.cargarCadroATRubro();
    }
    
    cargarCadroATRubro(){
        let asistencia_tecnica_agricola_filtro=[];
        if (this.departamento_seleccionado_asistencia_tecnica_agricola==null){
            asistencia_tecnica_agricola_filtro=this.asistencia_tecnica_agricola;
        }else{
            if (this.distrito_seleccionado_asistencia_tecnica_agricola==null){
                let departamento_seleccionado_asistencia_tecnica_agricola_aux=this.departamento_seleccionado_asistencia_tecnica_agricola;
                asistencia_tecnica_agricola_filtro = this.asistencia_tecnica_agricola.filter(function(item){
                    return item.departamento==departamento_seleccionado_asistencia_tecnica_agricola_aux;
                });
            }else{
                let departamento_seleccionado_asistencia_tecnica_agricola_aux=this.departamento_seleccionado_asistencia_tecnica_agricola;
                let distrito_seleccionado_asistencia_tecnica_agricola_aux=this.distrito_seleccionado_asistencia_tecnica_agricola;
                asistencia_tecnica_agricola_filtro = this.asistencia_tecnica_agricola.filter(function(item){
                    return (item.departamento==departamento_seleccionado_asistencia_tecnica_agricola_aux && item.distrito==distrito_seleccionado_asistencia_tecnica_agricola_aux);
                });
            }
                
        }
        
        let asistencia_tecnica_agricola_by_rubro = this.groupBy(asistencia_tecnica_agricola_filtro, x => x.cultivos);
        const asistencia_tecnica_agricola_by_rubro_array = [...asistencia_tecnica_agricola_by_rubro].map(function (item) {
            return { key: item[0], value: item[1], total_sup: 0 }
        });
        
        let rubros=[];
        let datos=[];
        
        for(let rr=0;rr<asistencia_tecnica_agricola_by_rubro_array.length;rr++){
            
            for (let dd = 0; dd < asistencia_tecnica_agricola_by_rubro_array[rr].value.length; dd++){
                asistencia_tecnica_agricola_by_rubro_array[rr].total_sup=asistencia_tecnica_agricola_by_rubro_array[rr].total_sup+asistencia_tecnica_agricola_by_rubro_array[rr].value[dd].total_sup;
            }
            datos.push(asistencia_tecnica_agricola_by_rubro_array[rr].total_sup);
            rubros.push(asistencia_tecnica_agricola_by_rubro_array[rr].key+' ('+asistencia_tecnica_agricola_by_rubro_array[rr].total_sup.toFixed(2)+')');
        }
        
        this.asistencia_tecnica_agricola_data={
            labels: rubros,
            datasets: [
                {
                    label: 'Superficie Total',
                    backgroundColor: '#7E57C2',
                    data: datos
                }
            ]
        };
        
        console.log(this.asistencia_tecnica_agricola_data);
        
    }
    
    groupBy<K, V>(array: V[], grouper: (item: V) => K) {
        return array.reduce((store, item) => {
            var key = grouper(item)
            if (!store.has(key)) {
                store.set(key, [item])
            } else {
                store.get(key).push(item)
            }
            return store
        }, new Map<K, V[]>())
    }

}
