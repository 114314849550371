<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

    <!-- Sidebar Toggle (Topbar) -->
    <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" (click)="sidebarToggle()">
        <i class="bi bi-list"></i>
    </button>

                    

                    <!-- Topbar Navbar -->
                    <ul class="navbar-nav ml-auto">
                        <!-- Nav Item - Alerts -->
                        <!--
                        <li class="nav-item dropdown no-arrow mx-1">
                            <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="bi bi-bell"></i>

                                <span class="badge badge-danger badge-counter">5</span>
                            </a>
                            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="alertsDropdown">
                                <h6 class="dropdown-header">
                                    Alertas
                                </h6>
                                
                                <li class="dropdown-item d-flex align-items-center" *ngFor="let alerta of alertas">
                                    <div class="mr-3">
                                        <div class="icon-circle bg-{{alerta.tipo}}">
                                            <fa-icon [icon]="faExclamationTriangle" [styles]="{'color': 'white'}"></fa-icon>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="small text-gray-500">{{myDate | date: 'dd MMM yyyy'}}</div>
                                        {{alerta.mensaje}}
                                    </div>
                                </li>
                                
                                <li class="dropdown-item text-center small text-gray-500">No hay mensajes</li>
                            </div>
                        </li>
                        -->

                        <!-- Nav Item - Messages -->
                        

                        <div class="topbar-divider d-none d-sm-block"></div>

                        <!-- Nav Item - User Information -->
                        <li class="nav-item dropdown no-arrow">
                            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{usuario.nombre}}</span>
                                <img class="img-profile rounded-circle"
                                    src="../../assets/img/undraw_profile.svg">
                            </a>
                            <!-- Dropdown - User Information -->
                            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="userDropdown">
                                <a class="dropdown-item">
                                    <i class="bi bi-person"></i>
                                    Perfil
                                </a>
                                <a class="dropdown-item" role="button" (click)="showModalDialog()">
                                    <i class="bi bi-calendar"></i>
                                    Gesti&oacute;n
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" role="button" (click)="logOut()">
                                    <i class="bi bi-box-arrow-right"></i>
                                    Salir
                                </a>
                            </div>
                        </li>

                    </ul>

                </nav>
<p-dialog header="Gestion" [(visible)]="displayModal" [modal]="true" [style]="{width: '20vw'}"
    [draggable]="false" [resizable]="false">
        <p-dropdown [options]="gestiones" [(ngModel)]="gestion"  inputId="gestion"  placeholder="[Seleccione]" optionLabel="gestion" optionValue="valor" appendTo="body" [style]="{width: '100%'}"></p-dropdown>
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="cambiarGestion()" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>