<!--<menu style="margin-top: 0px; padding-left: 0px"></menu>-->
<!-- Page Wrapper -->
<div id="wrapper">
    <!-- Sidebar -->
    <app-menu-lateral style="background-color: #224abe;"></app-menu-lateral>
    <!-- End of Sidebar -->
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
        <!-- Main Content -->
        <div id="content">
            <!-- Topbar -->
            <app-menu-superior></app-menu-superior>
            <!-- End of Topbar -->
            <!-- Begin Page Content -->
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/inicio']">Inicio</a></li>
                        <li class="breadcrumb-item active" aria-current="page">PPI/PROMAFI</li>
                    </ol>
                </nav>
                <br />
                <p-toast></p-toast>
                <p-contextMenu #cm [model]="items"></p-contextMenu>
                <p-confirmDialog icon="pi pi-exclamation-triangle" acceptLabel="Si" rejectLabel="No"></p-confirmDialog>
                <p-table [value]="ppipromafi" #dt [rows]="10" dataKey="id" [(contextMenuSelection)]="ppipromafiSeleccionado" [contextMenu]="cm" [scrollable]="true" [style]="{width:'100%'}" [showCurrentPageReport]="true" [resizableColumns]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-sm" responsiveLayout="stack"  editMode="row" dataKey="ppipromafiid"
                    [resetPageOnSort]="false" [paginator]="true" currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} registros"
                    [globalFilterFields]="['organizacion','ruc','proyecto','plan','nombre','apellido','ci','fechanac','sexo','supha','dependientes','nocelular','dptonombre','dtonombre','fechaobligado','latitud','longitud','xxx','yyy']">
                    
                    
                    <ng-template pTemplate="caption">
                        <div class="p-d-flex">
                            <button type="button" pButton pRipple icon="pi pi-plus" class="p-mr-2 p-button-rounded p-button-outlined" (click)="nuevoPpiPromafi()"></button>
                            <button type="button" pButton pRipple icon="pi pi-calendar" class="p-button-rounded p-button-secondary p-button-outlined p-mr-2" (click)="displayModalGestion=true"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-ml-auto"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        </div>
                    </ng-template>
                    
                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <col style="width: 6rem;"> <!--bot-->
                            <col style="width: 200px"> <!--Org-->
                            <col style="width: 150px"> <!--Ruc-->
                            <col style="width: 150px"> <!--Pro-->
                            <col style="width: 100px"> <!--Pla-->
                            <col style="width: 150px"> <!--nom-->
                            <col style="width: 200px"> <!--ape-->
                            <col style="width: 130px"> <!--ci-->
                            <col style="width: 140px"> <!--fna-->
                            <col style="width: 100px"> <!--eda-->
                            <col style="width: 100px"> <!--sex-->
                            <col style="width: 100px"> <!--suh-->
                            <col style="width: 100px"> <!--dep-->
                            <col style="width: 120px"> <!--cel-->
                            <col style="width: 200px"> <!--dpt-->
                            <col style="width: 200px"> <!--dto-->
                            <col style="width: 200px"> <!--fob-->
                            <col style="width: 100px"> <!--lat-->
                            <col style="width: 100px"> <!--lon-->
                            <col style="width: 200px"> <!--xxx-->
                            <col style="width: 200px"> <!--yyy-->
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th></th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Organizaci&oacute;n
                                    <p-columnFilter type="text" field="organizacion" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    RUC
                                    <p-columnFilter type="text" field="ruc" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Proyecto
                                    <p-columnFilter type="text" field="proyecto" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Plan
                                    <p-columnFilter type="text" field="plan" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Nombre
                                    <p-columnFilter type="text" field="nombre" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Apellido
                                    <p-columnFilter type="text" field="apellido" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    CI
                                    <p-columnFilter type="text" field="ci" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Fecha Nac.
                                    <p-columnFilter type="date" field="fechanac" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Edad
                                    <p-columnFilter type="numeric" field="edad" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Sexo
                                    <p-columnFilter type="text" field="sexo" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Sup HA
                                    <p-columnFilter type="numeric" field="supha" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Deps.
                                    <p-columnFilter type="numeric" field="dependientes" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Celular
                                    <p-columnFilter type="text" field="nocelular" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Departamento
                                    <p-columnFilter type="text" field="dptonombre" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Distrito
                                    <p-columnFilter type="text" field="dtonombre" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Fecha Obligado
                                    <p-columnFilter type="date" field="fechaobligado" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Latitud
                                    <p-columnFilter type="text" field="latitud" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    Longitud
                                    <p-columnFilter type="text" field="longitud" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    1 Desembolso PYG
                                    <p-columnFilter type="text" field="xxx" display="menu"></p-columnFilter>
                                </div>
                            </th>
                            <th pResizableColumn>
                                <div class="flex justify-content-center align-items-center">
                                    2 Desembolso PYG
                                    <p-columnFilter type="text" field="yyy" display="menu"></p-columnFilter>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-ppipromafis let-editing="editing" let-ri="rowIndex">
                        <tr [pContextMenuRow]="ppipromafis" [pEditableRow]="ppipromafis">
                            
                            <td>
                                <!--<i class="bi bi-folder2-open" role="button" data-toggle="modal" data-target="#exampleModal"></i>-->
                                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(ppipromafis)" class="p-button-rounded p-button-text"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(ppipromafis)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(ppipromafis, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.organizacion" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.organizacion}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.ruc" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.ruc}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [options]="proyectos" [(ngModel)]="ppipromafis.proyectoid"  placeholder="[Seleccione]" optionLabel="proyecto" optionValue="proyectoid" appendTo="body" [style]="{width: '100%'}" (onChange)="cambioProyecto(ppipromafis)" ></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.proyecto}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.plan" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.plan}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.nombre" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.nombre}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.apellido" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.apellido}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" step="1" [(ngModel)]="ppipromafis.ci" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.ci}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-calendar dateFormat="dd/mm/yy" appendTo="body" [(ngModel)]="ppipromafis.fechanac" (ngModelChange)="calcularEdad(ppipromafis)" ></p-calendar>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.fechanac | date:'dd/MM/yyyy'}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>{{ppipromafis.edad}}</td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-radioButton name="groupname{{ppipromafis.vmgid}}" value="M" [(ngModel)]="ppipromafis.sexo" inputId="sexom{{ppipromafis.vmgid}}"></p-radioButton>
                                        <label for="sexom{{ppipromafis.vmgid}}">M</label>
                                        <p-radioButton name="groupname{{ppipromafis.vmgid}}" value="F" [(ngModel)]="ppipromafis.sexo" inputId="sexof{{ppipromafis.vmgid}}"></p-radioButton>
                                        <label for="sexof{{ppipromafis.vmgid}}">F</label>

                                            
                                        <!--
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="sexom{{vmgs.vmgid}}" name="sexo{{vmgs.vmgid}}" value="M" [(ngModel)]="vmgs.sexo" class="custom-control-input">
                                            <label class="custom-control-label" for="sexom{{vmgs.vmgid}}">M</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="sexof{{vmgs.vmgid}}" name="sexo{{vmgs.vmgid}}" value="F" [(ngModel)]="vmgs.sexo" class="custom-control-input">
                                            <label class="custom-control-label" for="sexof">F</label>
                                        </div>
                                        -->
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.sexo}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.supha" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.supha}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.dependientes" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.dependientes}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.nocelular" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.nocelular}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [options]="departamentos" [(ngModel)]="ppipromafis.dptoid"  placeholder="[Seleccione]" optionLabel="dptonombre" optionValue="dptoid" [filter]="true" [showClear]="true" appendTo="body" [style]="{width: '100%'}" (onChange)="cambioDepartamentoEdit(ppipromafis)" ></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.dptonombre}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown [options]="distritosFiltradoEdit[ppipromafis.ppipromafiid]" [(ngModel)]="ppipromafis.dptodtoid"  placeholder="[Seleccione]" optionLabel="dtonombre" optionValue="dtoid" [filter]="true" [showClear]="true" appendTo="body" [style]="{width: '100%'}" [disabled]="ppipromafis.dptoid==null" (onChange)="cambioDistrito(ppipromafis)" ></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.dtonombre}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            
                            
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-calendar dateFormat="dd/mm/yy" inputId="fechaobligado{{ppipromafis.ppipromafiid}}" appendTo="body" [(ngModel)]="ppipromafis.fechaobligado" [style]="{width: '100%'}"></p-calendar>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.fechaobligado | date:'dd/MM/yyyy'}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.latitud" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.latitud}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.longitud" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.longitud}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.xxx" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.xxx}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ppipromafis.yyy" style="width: 100%;">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ppipromafis.yyy}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            
                            
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<p-dialog header="{{cabeceraModal}}" [(visible)]="displayModal" [modal]="true" [style]="{width: '70vw'}" 
    [draggable]="true" [resizable]="false" (onHide)="cerrarVentana()">
    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Datos Generales">
            <div class="row mb-3">
                <div class="col-md-2">
                    <label for="ci">CI</label>
                    <div class="input-group mb-3">
                        <input type="number" step="1" [ngClass]="error_ci ? 'form-control is-invalid' : 'form-control'" id="ci" name="ci" [(ngModel)]="ppipromafiSeleccionado.ci" (change)="error_ci=false"  aria-describedby="buscar_ci" />
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button" id="buscar_ci" (click)="buscarCI()"><i class="fas fa-search"></i></button>
                        </div>
                        <div class="invalid-feedback">
                            {{mensaje_error_ci}}
                        </div>
                    </div>

                    <!--
                    <input type="number" step="1" [ngClass]="error_ci ? 'form-control is-invalid' : 'form-control'" id="ci" name="ci" [(ngModel)]="ppipromafiSeleccionado.ci" (change)="error_ci=false" />
                    <div class="invalid-feedback">
                        {{mensaje_error_ci}}
                    </div>
                    -->
                </div>
                <div class="col-md-3">
                    <label for="nombre">Nombre</label>
                    <input type="text" [ngClass]="error_nombre ? 'form-control is-invalid' : 'form-control'" id="nombre" name="nombre" [(ngModel)]="ppipromafiSeleccionado.nombre" (change)="error_nombre=false" />
                    <div class="invalid-feedback">
                        Campo Requerido
                    </div>
                </div>
                <div class="col-md-4">
                    <label for="apellido">Apellido</label>
                    <input type="text" class="form-control" id="apellido" name="apellido" [(ngModel)]="ppipromafiSeleccionado.apellido" />
                </div>

                <div class="col-md-3">
                    <label for="fechanac">Fecha Nacimiento</label>
                    <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" inputId="fechanac" appendTo="body" [(ngModel)]="ppipromafiSeleccionado.fechanac" (ngModelChange)="calcularEdad(ppipromafiSeleccionado)" [style]="{width: '100%'}" ></p-calendar>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <label for="organizacion">Organizacion</label>
                    <input type="text" class="form-control" id="organizacion" name="organizacion" [(ngModel)]="ppipromafiSeleccionado.organizacion" />
                </div>
                <div class="col-md-3">
                    <label for="ruc">RUC</label>
                    <input type="text" class="form-control" id="ruc" name="ruc" [(ngModel)]="ppipromafiSeleccionado.ruc" />
                </div>
                <div class="col-md-3">
                    <label for="organizacion">Proyecto</label>
                    <p-dropdown [options]="proyectos" [(ngModel)]="ppipromafiSeleccionado.proyectoid"  placeholder="[Seleccione]" optionLabel="proyecto" inputId="dptoid"  optionValue="proyectoid" appendTo="body" [style]="{width: '100%'}" (onChange)="cambioProyecto(ppipromafiSeleccionado)"></p-dropdown>
                    <!--<p-dropdown [options]="proyectos" [(ngModel)]="ppipromafis.proyectoid"             placeholder="[Seleccione]" optionLabel="proyecto"                   optionValue="proyectoid" appendTo="body" [style]="{width: '100%'}" (onChange)="cambioProyecto(ppipromafis)" ></p-dropdown>-->
                </div>
                <div class="col-md-2">
                    <label for="plan">Plan</label>
                    <input type="text" class="form-control" id="plan" name="plan" [(ngModel)]="ppipromafiSeleccionado.plan" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-3">
                    <p>Sexo</p>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="sexom" name="sexo" value="M" [(ngModel)]="ppipromafiSeleccionado.sexo" class="custom-control-input">
                        <label class="custom-control-label" for="sexom">Masc.</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="sexof" name="sexo" value="F" [(ngModel)]="ppipromafiSeleccionado.sexo" class="custom-control-input">
                        <label class="custom-control-label" for="sexof">Fem.</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <label for="supha">Sup. Ha</label>
                    <input type="numeric" step="0.01" class="form-control" id="supha" name="supha" [(ngModel)]="ppipromafiSeleccionado.supha" />
                </div>
                <div class="col-md-3">
                    <label for="dependientes">Dependientes</label>
                    <input type="numeric" step="1" class="form-control" id="dependientes" name="dependientes" [(ngModel)]="ppipromafiSeleccionado.dependientes" />
                </div>
                <div class="col-md-3">
                    <label for="nocelular">No Celular</label>
                    <input type="text" class="form-control" id="nocelular" nocelular="yyy" [(ngModel)]="ppipromafiSeleccionado.nocelular" />
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-5">
                    <label for="dptoid">Departamento</label>
                    <p-dropdown [options]="departamentos" [(ngModel)]="ppipromafiSeleccionado.dptoid"  inputId="dptoid"  placeholder="[Seleccione]" optionLabel="dptonombre" optionValue="dptoid" [filter]="true" [showClear]="true" appendTo="body" [style]="{width: '100%'}" (onChange)="cambioDepartamento(ppipromafiSeleccionado)"></p-dropdown>
                </div>
                <div class="col-md-4">
                    <label for="dptodtoid">Distrito</label>
                    <p-dropdown [options]="distritosFiltrado" [(ngModel)]="ppipromafiSeleccionado.dptodtoid"  inputId="dptodtoid"  placeholder="[Seleccione]" optionLabel="dtonombre" optionValue="dtoid" [filter]="true" [showClear]="true" appendTo="body" [style]="{width: '100%'}" [disabled]="ppipromafiSeleccionado.dptoid==null" (onChange)="cambioDistrito()" ></p-dropdown>
                </div>
                <div class="col-md-3">
                    <label for="fechaobligado">Fecha de Obligado</label>
                    <p-calendar [showIcon]="true" dateFormat="dd/mm/yy" inputId="fechaobligado" appendTo="body" [(ngModel)]="ppipromafiSeleccionado.fechaobligado" [style]="{width: '100%'}" ></p-calendar>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-3">
                    <label for="latitud">Latitud</label>
                    <input type="text" class="form-control" id="latitud" name="latitud" [(ngModel)]="ppipromafiSeleccionado.latitud" />
                </div>
                <div class="col-md-3">
                    <label for="longitud">Longitud</label>
                    <input type="text" class="form-control" id="longitud" name="longitud" [(ngModel)]="ppipromafiSeleccionado.longitud" />
                </div>
                <div class="col-md-3">
                    <label for="xxx">1er Desembolso PYG</label>
                    <input type="number" step="0.01" class="form-control" id="xxx" name="xxx" [(ngModel)]="ppipromafiSeleccionado.xxx" />
                </div>
                <div class="col-md-3">
                    <label for="yyy">2do Desembolso PYG</label>
                    <input type="number" step="0.01" class="form-control" id="yyy" name="yyy" [(ngModel)]="ppipromafiSeleccionado.yyy" />
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Gestiones">
            <ng-container *ngFor="let gestions of gestiones; let ag = index;">
                <div class="p-field-checkbox">
                    <p-checkbox name="gestions" value="{{gestions.gestion}}" [(ngModel)]="ppipromafiSeleccionado.gestion" inputId="gestions{{ag}}"></p-checkbox>
                    <label for="gestions{{ag}}">{{gestions.gestion}}</label>
                </div>
            </ng-container>
        </p-tabPanel>
            
    </p-tabView>
        
    
    
    
    
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="guardarDatos()" label="Guardar" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>


<p-dialog header="Gestion" [(visible)]="displayModalGestion" [modal]="true" [style]="{width: '30vw'}" 
    [draggable]="false" [resizable]="false">
    <p>Agregar la gesti&oacute;n a todos los registros</p>
    <p-dropdown [options]="gestiones" [(ngModel)]="gestion"  inputId="gestion"  placeholder="[Seleccione]" optionLabel="gestion" optionValue="valor" appendTo="body" [style]="{width: '100%'}"></p-dropdown>
    <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="agreagrGestion()" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>