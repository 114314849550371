import { Component, OnInit } from '@angular/core';
import {UsuariosService} from '../services/usuarios.service';
import {RafiService} from '../services/rafi.service';
import {RafaService} from '../services/rafa.service';
import {VmgService} from '../services/vmg.service';
import { ToastrService } from 'ngx-toastr';
import {AsistenaciaTecnicaModel} from "../models/asistencia-tecnica.model";

@Component({
    selector: 'app-asistencia-tecnica',
    templateUrl: './asistencia-tecnica.component.html',
    styleUrls: ['./asistencia-tecnica.component.css'],
    providers:[UsuariosService,RafiService,RafaService,VmgService]
})
export class AsistenciaTecnicaComponent implements OnInit {
    public token: string;
    public gestion: string;
    public gestion_local: string;
    
    public asistencia_tecnica: Array<AsistenaciaTecnicaModel>;
    public asistencia_tecnica_xlsx: Array<any>;

    constructor(
        private _usuariosService: UsuariosService,
        private _rafiService: RafiService,
        private _rafaService: RafaService,
        private _vmgService: VmgService,
        private _toastr: ToastrService
        ) {
            this.token = this._usuariosService.getToken();
            this.gestion = this._usuariosService.getGestion();
            this.gestion_local=this.gestion;
            if(this.gestion == ''){
                this.gestion_local = String(new Date().getFullYear());
            }
    }

    ngOnInit(): void {
        this.asistencia_tecnica=[];
        this.asistencia_tecnica_xlsx=[];
        var asistencia_tecnica_aux=[];
        this._rafiService.getRafiData(this.token, this.gestion).subscribe(
            response =>{
                //console.log("Rafi:");
                //console.log(response);
                //this.rafi=response;
                response.forEach(function(rafi) {
                    asistencia_tecnica_aux.push({
                        cic_nro: rafi.cic_nro,
                        nombre_apellido: rafi.nombre_apellido,
                        fecha_nac: rafi.fecha_nac,
                        edad: rafi.edad,
                        sexo: rafi.sexo,
                        tipo: rafi.tipo,
                        cod_dpto: rafi.cod_dpto,
                        departamento: rafi.departamento,
                        id_distrito: rafi.id_distrito,
                        distrito: rafi.distrito
                    });
                });
                this._rafaService.getRafaData(this.token, this.gestion).subscribe(
                    response_rafa =>{
                        console.log("Rafa:");
                        console.log(response_rafa);
                        response_rafa.forEach(function(rafa) {
                            asistencia_tecnica_aux.push({
                                cic_nro: rafa.cic_nro,
                                nombre_apellido: rafa.nombre_apellido,
                                fecha_nac: rafa.fecha_nac,
                                edad: rafa.edad,
                                sexo: rafa.sexo,
                                tipo: rafa.tipo,
                                cod_dpto: rafa.cod_dpto,
                                departamento: rafa.departamento,
                                id_distrito: rafa.id_distrito,
                                distrito: rafa.distrito
                            });
                        });
                        this._vmgService.getVmgData(this.token, this.gestion_local).subscribe(
                            response_vmg =>{
                                console.log("VMG:");
                                console.log(response_vmg);
                                let gestion_actual = this.gestion_local;
                                response_vmg.forEach(function(vmg) {
                                    if(vmg.gestion.includes(gestion_actual)){
                                        asistencia_tecnica_aux.push({
                                            cic_nro: vmg.ci,
                                            nombre_apellido: vmg.nombre+' '+vmg.nombre,
                                            fecha_nac: vmg.fechanac,
                                            edad: vmg.edad,
                                            sexo: vmg.sexo,
                                            tipo: vmg.tipoproyecto,
                                            cod_dpto: vmg.dptoid,
                                            departamento: vmg.dptonombre,
                                            id_distrito: vmg.dptodtoid,
                                            distrito: vmg.dtonombre
                                        });
                                    }
                                        
                                });
                                this.asistencia_tecnica = asistencia_tecnica_aux;
                                for (let at = 0; at<this.asistencia_tecnica.length; at++){
                                    this.asistencia_tecnica_xlsx.push({
                                        'CI': this.asistencia_tecnica[at].cic_nro,
                                        'Nombre y Apellido': this.asistencia_tecnica[at].nombre_apellido,
                                        'Sexo': this.asistencia_tecnica[at].sexo,
                                        'Tipo de Beneficiarios': this.asistencia_tecnica[at].tipo,
                                        'Departamento': this.asistencia_tecnica[at].departamento,
                                        'Distrito': this.asistencia_tecnica[at].distrito,
                                        'Edad': this.asistencia_tecnica[at].edad
                                    });
                                }
                                //console.log(this.asistencia_tecnica);
                            },
                            error=>{
                                let mensaje_error=<any>error;
                                this._toastr.error(mensaje_error.error.mensaje, 'Error');
                            }
                        );
                    },
                    error=>{
                        let mensaje_error=<any>error;
                        this._toastr.error(mensaje_error.error.mensaje, 'Error');
                    }
                );
            },
            error=>{
                let mensaje_error=<any>error;
                this._toastr.error(mensaje_error.error.mensaje, 'Error');
            }
        );
        
                

        
        
                        
        
    }
    
    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.asistencia_tecnica_xlsx);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "AsistenciaTecnica");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import("file-saver").then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

}
